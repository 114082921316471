import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ClassSelection from '../components/registration/ClassSelection';
import ParentRegistrationForm from '../components/registration/ParentRegistrationForm';
import SchoolAddressSelection from '../components/registration/SchoolAddressSelection';
import SchoolLevelSelection from '../components/registration/SchoolLevelSelection';
import SchoolTypeSelection from '../components/registration/SchoolTypeSelection';
import StepProgressBar from '../components/registration/StepProgressBar';
import SubjectsSelection from '../components/registration/SubjectsSelection';
import { RegistrationFormData, School, Subject, ParentData } from '../model/registration/SchoolSelection';
import api from '../utils/api';
import { StepDetails } from './Registration';
import EmailConfirm from '../components/registration/EmailConfirm';
import { useLoading } from '../context/LoadingContext';
import { useError } from '../context/ErrorContext';

const SignUp: React.FC = () => {
  const defaultRegistrationData: RegistrationFormData = {
    currentStep: 0,
    onboardingData: {},
    selectedSchoolLevel: '',
    selectedSchoolType: '',
    selectedSchoolAddress: '',
    selectedSchoolClass: '',
    selectedSchoolSubjects: [],
    parentData: {
      parentFirstName: '',
      parentLastName: '',
      phone: '+39',
      city_id: null,
      province_id: null,
      street_name: '',
      street_number: '',
      zip: '',
      email: '',
      cf: '',
      studentFirstName: '',
      studentLastName: '',
    },
  };

  const loadRegistrationData = (): RegistrationFormData => {
    const storedData = localStorage.getItem('registrationForm');
    if (storedData) {
      return { ...defaultRegistrationData, ...JSON.parse(storedData) };
    }
    return defaultRegistrationData;
  };

  const [schoolLevelOptions, setSchoolLevelOptions] = useState<string[]>([]);
  const [schoolTypeOptions, setSchoolTypeOptions] = useState<string[]>([]);
  const [schoolAddressOptions, setSchoolAddressOptions] = useState<School[]>([]);
  const [classOptions, setClassOptions] = useState<{ name: string; value: string }[]>([]);
  const [subjectsOptions, setSubjectsOptions] = useState<Subject[]>([]);
  const [registrationData, setRegistrationData] = useState<RegistrationFormData>(loadRegistrationData());
  const { setLoading } = useLoading();
  const { setError } = useError();

  const {
    currentStep,
    onboardingData,
    selectedSchoolLevel,
    selectedSchoolType,
    selectedSchoolAddress,
    selectedSchoolClass,
    selectedSchoolSubjects,
    parentData,
  } = registrationData;

  useEffect(() => {
    localStorage.setItem('registrationForm', JSON.stringify(registrationData));
  }, [registrationData]);

  const updateRegistrationData = <T extends keyof RegistrationFormData>(
    property: T,
    value: RegistrationFormData[T]
  ) => {
    setRegistrationData((prevData) => ({
      ...prevData,
      [property]: value,
    }));
  };

  const updateParentDataField = <T extends keyof ParentData>(field: T, value: ParentData[T]) => {
    setRegistrationData((prevData) => ({
      ...prevData,
      parentData: {
        ...prevData.parentData,
        [field]: value,
      },
    }));
  };

  useEffect(() => {
    setLoading(true);
    api
      .get('/school')
      .then((response) => {
        updateRegistrationData('onboardingData', response.data);
        setSchoolLevelOptions(Object.keys(response.data));
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (selectedSchoolLevel && onboardingData[selectedSchoolLevel]) {
      const types = Object.keys(onboardingData[selectedSchoolLevel]);
      setSchoolTypeOptions(types);
      if (selectedSchoolType && onboardingData[selectedSchoolLevel][selectedSchoolType]) {
        const addresses = onboardingData[selectedSchoolLevel][selectedSchoolType];
        setSchoolAddressOptions(addresses);
        if (selectedSchoolAddress && addresses.find((addr) => addr.name === selectedSchoolAddress)) {
          const years =
            selectedSchoolLevel === 'ms'
              ? [
                  { name: 'Primo anno', value: '1' },
                  { name: 'Secondo anno', value: '2' },
                  { name: 'Terzo anno', value: '3' },
                ]
              : [
                  { name: 'Primo anno', value: '1' },
                  { name: 'Secondo anno', value: '2' },
                  { name: 'Terzo anno', value: '3' },
                  { name: 'Quarto anno', value: '4' },
                  { name: 'Quinto anno', value: '5' },
                ];
          setClassOptions(years);
          if (selectedSchoolClass) {
            const subjects = addresses.find((addr) => addr.name === selectedSchoolAddress)?.subjects || [];
            setSubjectsOptions(subjects);
          }
        }
      }
    }
  }, [selectedSchoolAddress, selectedSchoolLevel, selectedSchoolType, selectedSchoolClass, onboardingData]);

  const steps: StepDetails[] = [
    {
      stepComponent: (
        <SchoolLevelSelection
          onNext={() => handleNextStep()}
          options={schoolLevelOptions}
          setSelectedSchoolLevel={(newValue) => updateRegistrationData('selectedSchoolLevel', newValue)}
        />
      ),
      stepId: 'schoolSelection',
    },
    {
      stepComponent: schoolTypeOptions && (
        <SchoolTypeSelection
          onSchoolTypeSelection={() => handleNextStep()}
          options={schoolTypeOptions}
          setSelectedSchoolType={(newValue) => updateRegistrationData('selectedSchoolType', newValue)}
        />
      ),
      stepId: 'schoolType',
    },
    {
      stepComponent: schoolAddressOptions && (
        <SchoolAddressSelection
          onSchoolAddressSelection={() => handleNextStep()}
          options={schoolAddressOptions}
          setSelectedSchoolAddress={(newValue) => updateRegistrationData('selectedSchoolAddress', newValue)}
        />
      ),
      stepId: 'schoolAddress',
    },
    {
      stepComponent: classOptions && (
        <ClassSelection
          onClassSelection={() => handleNextStep()}
          options={classOptions}
          setSelectedSchoolClass={(newValue) => updateRegistrationData('selectedSchoolClass', newValue)}
        />
      ),
      stepId: 'classSelection',
    },
    {
      stepComponent: subjectsOptions && (
        <SubjectsSelection
          onSubjectsSelection={() => handleNextStep()}
          options={subjectsOptions}
          setSelectedSchoolSubjects={(newValue) => updateRegistrationData('selectedSchoolSubjects', newValue)}
        />
      ),
      stepId: 'subjectsSelection',
    },
    {
      stepComponent: (
        <div className='flex bg-white p-4 rounded-md shadow-md'>
          <ParentRegistrationForm
            onSubmit={() => handleNextStep()}
            registrationData={registrationData}
            onboardingData={onboardingData}
            updateParentData={updateParentDataField}
          />
        </div>
      ),
      stepId: 'familyData',
    },
    {
      stepComponent: <EmailConfirm />,
      stepId: 'sendEmail',
    },
  ];

  const handleNextStep = () => {
    if (currentStep < steps.length - 1) {
      updateRegistrationData('currentStep', currentStep + 1);
    } else {
      console.log('Process completed!');
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      updateRegistrationData('currentStep', currentStep - 1);
    }
  };

  return (
    <div className="flex flex-col gap-y-3">
      <StepProgressBar currentStepIndex={currentStep} steps={steps} />
      <div className="step-navigation mt-4 ml-4">
        {currentStep > 0 && (
          <button onClick={handlePreviousStep} className="previous-step">
            Indietro
          </button>
        )}
      </div>
      <div className="flex items-center justify-center">{steps[currentStep].stepComponent}</div>
    </div>
  );
};

export default SignUp;
