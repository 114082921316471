import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useLoading } from '../../context/LoadingContext';
import { useError } from '../../context/ErrorContext';
import api from '../../utils/api';

const Cancel: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  // Estrai l'ID dell'ordine dall'URL
  const orderId = searchParams.get('id');
  const { setLoading } = useLoading();
  const { setError } = useError();

  useEffect(() => {
    // Se c'è un ID nell'URL, fai la chiamata API
    if (orderId) {
      setLoading(true);
      api
        .delete(`/lesson/order/${orderId}`)
        .then((response) => {
          console.log('id', orderId);
        })
        .catch((error) => {
          console.error("Errore nel recupero dei dettagli dell'ordine:", error);
          setError(true, error.response.data.message, error.response.status);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [orderId, setError]);

  const handleGoToLessons = () => {
    navigate('/family/Home');
  };
  return (
    <div className="text-center p-4 bg-fpcwhite rounded-md shadow-md">
      <h1 className="text-2xl font-bold text-fpcred">Pagamento annullato!</h1>

      <p className="text-lg mt-4">
        Il tuo ordine è stato annullato. <br />
      </p>
      <div className="flex justify-center w-full p-4">
        <button onClick={handleGoToLessons} className="bg-fpcred rounded-md px-4 py-2 font-bold text-white shadow-md">
          Torna alla pagina delle tue lezioni
        </button>
      </div>
    </div>
  );
};

export default Cancel;
