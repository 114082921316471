import IconProps from '../../interfaces/IconProps';
import { ReactComponent as ActiveDocumentAddIcon } from './assets/act-document-add.svg';
import { ReactComponent as DefaultDocumentAddIcon } from './assets/dis-document-add.svg';
import { ReactComponent as ActiveCalendarIcon } from './assets/act-calendar.svg';
import { ReactComponent as DefaultCalendarIcon } from './assets/dis-calendar.svg';
import { ReactComponent as ActiveRoutingIcon } from './assets/act-routing.svg';
import { ReactComponent as DefaultRoutingIcon } from './assets/dis-routing.svg';
import { ReactComponent as ActiveUserCheckIcon } from './assets/act-user-check.svg';
import { ReactComponent as DefaultUserCheckIcon } from './assets/dis-user-check.svg';
import { ReactComponent as ActiveUserIcon } from './assets/act-user.svg';
import { ReactComponent as DefaultUserRoundedIcon } from './assets/dis-user.svg';
import { ReactComponent as ActiveAmministrativeSectionIcon } from './assets/act-amministrative.svg';
import { ReactComponent as DefaultAmministrativeSectionIcon } from './assets/dis-amministrative.svg';

const DocumentAddIcon: React.FC<IconProps> = (iconProps) => {
  return iconProps.options.activeState ? (
    <ActiveDocumentAddIcon
      width={iconProps.options.width}
      height={iconProps.options.height}
      className={iconProps.options.className}
    />
  ) : (
    <DefaultDocumentAddIcon
      width={iconProps.options.width}
      height={iconProps.options.height}
      className={iconProps.options.className}
    />
  );
};

const AmministrativeSectionIcon: React.FC<IconProps> = (iconProps) => {
  return iconProps.options.activeState ? (
    <ActiveAmministrativeSectionIcon
      width={iconProps.options.width}
      height={iconProps.options.height}
      className={iconProps.options.className}
    />
  ) : (
    <DefaultAmministrativeSectionIcon
      width={iconProps.options.width}
      height={iconProps.options.height}
      className={iconProps.options.className}
    />
  );
};

const NotebookSquareIcon: React.FC<IconProps> = (iconProps) => {
  return iconProps.options.activeState ? (
    <ActiveCalendarIcon
      width={iconProps.options.width}
      height={iconProps.options.height}
      className={iconProps.options.className}
    />
  ) : (
    <DefaultCalendarIcon
      width={iconProps.options.width}
      height={iconProps.options.height}
      className={iconProps.options.className}
    />
  );
};

const RoutingIcon: React.FC<IconProps> = (iconProps) => {
  return iconProps.options.activeState ? (
    <ActiveRoutingIcon
      width={iconProps.options.width}
      height={iconProps.options.height}
      className={iconProps.options.className}
    />
  ) : (
    <DefaultRoutingIcon
      width={iconProps.options.width}
      height={iconProps.options.height}
      className={iconProps.options.className}
    />
  );
};

const UserCheckIcon: React.FC<IconProps> = (iconProps) => {
  return iconProps.options.activeState ? (
    <ActiveUserCheckIcon
      width={iconProps.options.width}
      height={iconProps.options.height}
      className={iconProps.options.className}
    />
  ) : (
    <DefaultUserCheckIcon
      width={iconProps.options.width}
      height={iconProps.options.height}
      className={iconProps.options.className}
    />
  );
};

const UserRoundedIcon: React.FC<IconProps> = (iconProps) => {
  return iconProps.options.activeState ? (
    <ActiveUserIcon
      width={iconProps.options.width}
      height={iconProps.options.height}
      className={iconProps.options.className}
    />
  ) : (
    <DefaultUserRoundedIcon
      width={iconProps.options.width}
      height={iconProps.options.height}
      className={iconProps.options.className}
    />
  );
};

const renderIcon = (iconProps: IconProps) => {
  const { iconName } = iconProps;
  const { width, height, activeState, className } = iconProps.options;

  switch (iconName) {
    case 'document-add':
      return (
        <DocumentAddIcon options={{ width: width, height: height, activeState: activeState, className: className }} />
      );
    case 'notebook-square':
      return (
        <NotebookSquareIcon
          options={{ width: width, height: height, activeState: activeState, className: className }}
        />
      );
    case 'amministrative-section':
      return (
        <AmministrativeSectionIcon
          options={{ width: width, height: height, activeState: activeState, className: className }}
        />
      );
    case 'routing':
      return <RoutingIcon options={{ width: width, height: height, activeState: activeState, className: className }} />;
    case 'user-check':
      return (
        <UserCheckIcon options={{ width: width, height: height, activeState: activeState, className: className }} />
      );
    case 'user-rounded':
      return (
        <UserRoundedIcon options={{ width: width, height: height, activeState: activeState, className: className }} />
      );
    default:
      return null;
  }
};

const Icon: React.FC<IconProps> = (iconProps) => {
  return renderIcon(iconProps);
};

export default Icon;
