import React, { useState, useEffect } from 'react';
import { EventInput } from '@fullcalendar/core';
import { useError } from '../../context/ErrorContext';
import { useLoading } from '../../context/LoadingContext';
import api from '../../utils/api';

interface Props {
  selectedEvent: EventInput;
  closeModal: () => void;
  handleEditModal: () => void;
}

const EditTeacherLesson: React.FC<Props> = ({ selectedEvent, closeModal, handleEditModal }) => {
  const { setLoading } = useLoading();
  const { setError } = useError();
  const [meetLink, setMeetLink] = useState(selectedEvent.extendedProps.metadata?.link || '');
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleSaveLink = async () => {
    try {
      setLoading(true);
      await api.post(`lesson/${selectedEvent.id}/link`, { link: meetLink });
    } catch (error) {
      console.error('Errore durante il salvataggio del link', error);
      setError(true, error.response?.data?.message || 'Errore sconosciuto', error.response?.status || 500);
    } finally {
      //window.location.reload();
      setLoading(false);
    }
  };

  const handleCloseAndMaybeSave = async () => {
    closeModal();
    if (meetLink.trim() !== '') {
      try {
        await handleSaveLink();
      } catch (error) {
        console.error('Errore durante il salvataggio del link', error);
        return;
      }
    }
  };

  const handleDelete = async () => {
    if (!confirmDelete) {
      setConfirmDelete(true);
      return;
    }
    closeModal();
    try {
      setLoading(true);
      await api.delete(`lesson/${selectedEvent.id}`);
    } catch (error) {
      console.error("Errore durante l'eliminazione della lezione", error);
      setError(true, error.response?.data?.message || 'Errore sconosciuto', error.response?.status || 500);
    } finally {
      //window.location.reload();
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("L'evento selezionato:", selectedEvent);
  }, [selectedEvent]);

  return (
    <div
      className="fixed inset-0 bg-gray-500 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center"
      onClick={handleCloseAndMaybeSave}>
      <div
        className="bg-white p-4 mx-auto w-96 rounded-lg shadow-lg flex flex-col gap-2"
        onClick={(e) => e.stopPropagation()}>
        {!confirmDelete ? (
          <>
            <div className="flex justify-between">
              <div className="flex flex-col">
                <h2 className="text-fpcred font-bold text-xl">Informazioni sulla lezione</h2>
              </div>
              <button onClick={handleCloseAndMaybeSave}>
                <p className="flex items-center justify-center w-8 h-8 rounded-full border border-fpcred text-fpcred hover:bg-fpcred hover:text-white transition-colors">
                  X
                </p>
              </button>
            </div>
            <div className="flex flex-1 flex-col">
              <div className="flex gap-2">
                <p className="font-bold">Responsabile:</p>
                <p>
                  {selectedEvent.extendedProps.guardian.first_name} {selectedEvent.extendedProps.guardian.last_name}
                </p>
              </div>
              <div className="flex gap-2">
                <p className="font-bold">Telefono:</p>
                <p>{selectedEvent.extendedProps.guardian.phone}</p>
              </div>
              <div className="flex gap-2">
                <p className="font-bold">Studente:</p>
                <p>
                  {selectedEvent.extendedProps.student.first_name} {selectedEvent.extendedProps.student.last_name}
                </p>
              </div>
              <div className="flex gap-2">
                <p className="font-bold">Materia:</p>
                <p>{selectedEvent.extendedProps.subject.name}</p>
              </div>
              <div className="flex gap-2 items-center">
                <p className="font-bold">Link:</p>
                <input
                  type="url"
                  className="px-4 h-8 py-2 rounded-md border-2 border-gray-500 w-full"
                  value={meetLink}
                  onChange={(e) => setMeetLink(e.target.value)}
                />
              </div>
            </div>
            <div className="flex justify-center gap-4">
              <button className="bg-fpcred text-white font-bold rounded-lg w-full p-2" onClick={handleDelete}>
                Cancella lezione
              </button>
              <button className="bg-fpcred text-white font-bold rounded-lg w-full p-2" onClick={handleEditModal}>
                Modifica lezione
              </button>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center gap-4">
            <h2 className="text-fpcred font-bold text-xl">Sei sicuro di voler cancellare la lezione?</h2>
            <div className="flex justify-center gap-4">
              <button
                className="bg-fpcred text-white font-bold rounded-md w-full p-2"
                onClick={() => setConfirmDelete(false)}>
                Annulla
              </button>
              <button className="bg-fpcpink text-fpcred font-bold rounded-md w-full p-2" onClick={handleDelete}>
                Conferma
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditTeacherLesson;
