import './App.css';
import './i18n/config';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MainContent from './pages/MainContent';
import Home from './pages/Home';
import About from './pages/About';
import Pricing from './pages/Pricing';
import WorkWithUs from './pages/WorkWithUs';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Registration from './pages/Registration';
import RegistrationConfirm from './pages/RegistrationConfirm';
import Signup from './pages/Signup';
import FamilyHome from './pages/family/FamilyHome';
import FamilyProfile from './pages/family/Profile';
import TeacherHome from './pages/teacher/TeacherHome';
import TeacherProfile from './pages/teacher/Profile';
import TeacherAdministrative from './pages/teacher/Administrative';
import ForgotPassword from './pages/ForgotPassword';
import FamilyDocuments from './pages/family/Documents';
import TeacherRegistration from './pages/TeacherRegistration';
import FamilySuccess from './pages/family/Success';
import { LoadingProvider } from './context/LoadingContext';
import { ErrorProvider } from './context/ErrorContext';
import { ToastProvider } from './context/ToastContext';
import AdminLogin from './pages/admin/Login';
import AdminHome from './pages/admin/AdminHome';
import FamilyCancel from './pages/family/Cancel';

function App() {
  return (
    <>
      <Router>
        <LoadingProvider>
          <ErrorProvider>
            <ToastProvider>
              <Routes>
                <Route path="/" element={<MainContent Page={Home} />} />
                <Route path="/about" element={<MainContent Page={About} />} />
                <Route path="/pricing" element={<MainContent Page={Pricing} />} />
                <Route path="/workwithus" element={<MainContent Page={WorkWithUs} />} />
                <Route path="/login" element={<MainContent Page={Login} />} />
                <Route path="/forgot-password" element={<MainContent Page={ForgotPassword} />} />
                <Route path="/register" element={<MainContent Page={Registration} />}>
                  <Route path="" element={<Signup />} />
                  <Route path="confirm" element={<RegistrationConfirm />} />
                  <Route path="teacher" element={<TeacherRegistration />} />
                </Route>
                <Route path="/family">
                  <Route path="Home" element={<MainContent Page={FamilyHome} />} />
                  <Route path="profile" element={<MainContent Page={FamilyProfile} />} />
                  <Route path="documents" element={<MainContent Page={FamilyDocuments} />} />
                  <Route path="order/success" element={<MainContent Page={FamilySuccess} />} />
                  <Route path="order/cancel" element={<MainContent Page={FamilyCancel} />} />
                </Route>
                <Route path="/teacher">
                  <Route path="home" element={<MainContent Page={TeacherHome} />} />
                  <Route path="profile" element={<MainContent Page={TeacherProfile} />} />
                  <Route path="administrative" element={<MainContent Page={TeacherAdministrative} />} />
                </Route>
                <Route path="/admin">
                  <Route path="home" element={<MainContent Page={AdminHome} />} />
                  <Route path="login" element={<MainContent Page={AdminLogin} />} />
                  <Route index element={<Navigate to="/admin/home" />} />
                </Route>
                <Route path="*" element={<MainContent Page={NotFound} />} />
              </Routes>
            </ToastProvider>
          </ErrorProvider>
        </LoadingProvider>
      </Router>
    </>
  );
}

export default App;
