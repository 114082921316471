import React, { useEffect, useState } from 'react';
import { useLoading } from '../../context/LoadingContext';
import { useError } from '../../context/ErrorContext';
import api from '../../utils/api';
import NotificationsBar from '../../components/NotificationsBar';
import { ClockIcon, FileCheck, FileX, FileInput } from 'lucide-react';
import useScreenWidth from '../../hooks/useScreenWidth';

interface Invoice {
  client: {
    cf: string;
    city: string;
    company_name: string;
    first_name: string;
    id: number;
    last_name: string;
    province: string;
    street_name: string;
    street_number: string;
    vat_number: string;
    zip: string;
  };
  created_at: string;
  description: string;
  id: number;
  number: number;
  provider: { id: number };
  status: string;
  subtotal: number;
  uuid: string;
}

const Documents: React.FC = () => {
  const [invoices, setInvoices] = React.useState<Invoice[]>([]);
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const { setLoading } = useLoading();
  const { setError } = useError();
  const pageSize = 10;
  const screenWidth = useScreenWidth();

  const getStatusIcon = (status) => {
    switch (status) {
      case 'waiting':
        return <ClockIcon className="text-yellow-500" size={20} />;
      case 'sent':
        return <FileInput className="text-blue-500" size={20} />;
      case 'rejected':
        return <FileX className="text-red-500" size={20} />;
      case 'delivered':
      case 'not delivered':
        return <FileCheck className="text-green-500" size={20} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    setLoading(true);
    api
      .get('/invoice/passive')
      .then((response) => {
        console.log(response.data.invoices);
        setInvoices(response.data.invoices);
        setTotalInvoices(response.data.invoices.length);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // Implementare la paginazione lato client
  const [paginatedInvoices, setPaginatedInvoices] = useState<Invoice[]>([]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setPaginatedInvoices(invoices.slice(startIndex, endIndex));
  }, [invoices, currentPage]);

  const getPdfInvoice = (id: number) => {
    console.log(`Scaricando PDF per fattura ID: ${id}`);
    setLoading(true);
    api
      .get(`invoice/${id}/pdf`, { responseType: 'blob' })
      .then((response) => {
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
      })
      .catch((error) => {
        console.error('error is:', error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getXmlInvoice = (id: number) => {
    console.log(`Scaricando XML per fattura ID: ${id}`);
    setLoading(true);
    api
      .get(`invoice/${id}/xml`)
      .then((response) => {
        const xmlBlob = new Blob([response.data], { type: 'application/xml' });
        const xmlUrl = URL.createObjectURL(xmlBlob);
        window.open(xmlUrl, '_blank');
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const closeModal = () => {
    setSelectedInvoice(null);
  };
  if (invoices.length === 0) {
    return (
      <div className="bg-white rounded-xl p-4 md:p-6 max-w-full shadow-md">
        <h2 className="text-xl md:text-2xl font-bold text-fpcred mb-4">I tuoi documenti</h2>
        <p className="text-base md:text-lg text-gray-800">Non ci sono fatture da mostrare.</p>
      </div>
    );
  }
  return (
    <div className="w-full h-full flex flex-col gap-y-4">
      <div className="mb-2">
        <NotificationsBar page="I tuoi documenti" />
      </div>
      <div className="bg-white rounded-xl p-4 md:p-6 shadow-md">
        <h2 className="text-xl md:text-2xl font-bold text-fpcred mb-4">I tuoi documenti</h2>
        {screenWidth < 768 ? (
          // Visualizzazione mobile: card invece di tabella
          <div className="space-y-4 max-h-[500px] overflow-y-auto pr-2">
            {paginatedInvoices.map((invoice) => (
              <div
                key={invoice.id}
                className="border rounded-lg p-3 cursor-pointer hover:bg-gray-50 transition-colors duration-150"
                onClick={() => setSelectedInvoice(invoice)}>
                <div className="flex justify-between items-center mb-2">
                  <span className="font-medium">Fattura #{invoice.number}</span>
                  <div>{getStatusIcon(invoice.status)}</div>
                </div>
                <div className="text-sm space-y-1">
                  <p>
                    <span className="font-medium">Cliente:</span>{' '}
                    {`${invoice.client.first_name} ${invoice.client.last_name}`}
                  </p>
                  {invoice.client.company_name && (
                    <p>
                      <span className="font-medium">Azienda:</span> {invoice.client.company_name}
                    </p>
                  )}
                  <p>
                    <span className="font-medium">Descrizione:</span>{' '}
                    {invoice.description.length > 30
                      ? invoice.description.substring(0, 30) + '...'
                      : invoice.description}
                  </p>
                  <div className="flex justify-between mt-2">
                    <p>
                      <span className="font-medium">Data:</span> {new Date(invoice.created_at).toLocaleDateString()}
                    </p>
                    <p>
                      <span className="font-medium">Totale:</span>{' '}
                      {invoice.subtotal !== null && invoice.subtotal !== undefined
                        ? invoice.subtotal.toFixed(2) + ' €'
                        : '0.00 €'}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          // Visualizzazione desktop: tabella
          <div className="overflow-y-auto max-h-[500px] pb-4 border-4 border-gray-500 border-opacity-50 rounded-lg">
            <table className="min-w-full bg-white">
              <thead className="sticky top-0 bg-white">
                <tr className="bg-gray-100">
                  <th className="py-3 px-4 border-b font-semibold text-gray-700">Numero Fattura</th>
                  <th className="py-3 px-4 border-b font-semibold text-gray-700">Nome Cliente</th>
                  <th className="py-3 px-4 border-b font-semibold text-gray-700">Nome Azienda</th>
                  <th className="py-3 px-4 border-b font-semibold text-gray-700">Descrizione</th>
                  <th className="py-3 px-4 border-b font-semibold text-gray-700">Stato</th>
                  <th className="py-3 px-4 border-b font-semibold text-gray-700">Subtotale</th>
                  <th className="py-3 px-4 border-b font-semibold text-gray-700">Creata il</th>
                </tr>
              </thead>
              <tbody>
                {paginatedInvoices.map((invoice) => (
                  <tr
                    key={invoice.id}
                    className="cursor-pointer hover:bg-gray-100 transition-colors duration-150"
                    onClick={() => setSelectedInvoice(invoice)}>
                    <td className="py-3 px-4 border-b text-center">{invoice.number}</td>
                    <td className="py-3 px-4 border-b">{`${invoice.client.first_name} ${invoice.client.last_name}`}</td>
                    <td className="py-3 px-4 border-b">{invoice.client.company_name}</td>
                    <td className="py-3 px-4 border-b">{invoice.description}</td>
                    <td className="py-3 px-4 border-b flex justify-center">{getStatusIcon(invoice.status)}</td>
                    <td className="py-3 px-4 border-b text-right">
                      {invoice.subtotal !== null && invoice.subtotal !== undefined
                        ? invoice.subtotal.toFixed(2) + ' €'
                        : '0.00 €'}
                    </td>
                    <td className="py-3 px-4 border-b text-center">
                      {new Date(invoice.created_at).toLocaleDateString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {/* Paginazione */}
        {totalInvoices > pageSize && (
          <div className="flex flex-wrap justify-center items-center mt-6 space-x-1 md:space-x-2">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className={`px-2 py-1 md:px-3 md:py-1 text-sm md:text-base rounded-md ${
                currentPage === 1
                  ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                  : 'bg-fpcred text-white hover:bg-red-700'
              } transition-colors duration-150`}>
              &laquo;
            </button>
            {screenWidth < 768 ? (
              // Versione mobile: mostra solo pagina corrente e totale
              <span className="px-3 py-1 text-sm">
                Pagina {currentPage} di {Math.ceil(totalInvoices / pageSize)}
              </span>
            ) : (
              // Versione desktop: mostra tutti i numeri di pagina
              Array.from({ length: Math.ceil(totalInvoices / pageSize) }, (_, i) => i + 1).map((page) => (
                <button
                  key={page}
                  onClick={() => setCurrentPage(page)}
                  className={`px-3 py-1 rounded-md ${
                    currentPage === page ? 'bg-fpcred text-white' : 'bg-gray-200 hover:bg-gray-300'
                  } transition-colors duration-150`}>
                  {page}
                </button>
              ))
            )}
            <button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(totalInvoices / pageSize)))}
              disabled={currentPage === Math.ceil(totalInvoices / pageSize)}
              className={`px-2 py-1 md:px-3 md:py-1 text-sm md:text-base rounded-md ${
                currentPage === Math.ceil(totalInvoices / pageSize)
                  ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                  : 'bg-fpcred text-white hover:bg-red-700'
              } transition-colors duration-150`}>
              &raquo;
            </button>
          </div>
        )}
      </div>
      {selectedInvoice && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50"
          onClick={closeModal}>
          <div
            className="bg-white rounded-lg p-4 md:p-8 w-[90%] md:w-96 max-w-md relative shadow-xl"
            onClick={(e) => e.stopPropagation()}>
            <button
              className="absolute top-2 right-2 md:top-3 md:right-3 text-gray-600 hover:text-gray-800 transition-colors duration-150"
              onClick={closeModal}>
              <svg
                className="w-5 h-5 md:w-6 md:h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
            <h3 className="text-lg md:text-xl font-bold mb-4 md:mb-6 text-fpcred">Fattura {selectedInvoice.number}</h3>
            <div className="mb-4 md:mb-6 p-3 md:p-4 bg-gray-50 rounded-lg text-sm md:text-base">
              <div className="flex items-center mb-2">
                <div className="mr-2">{getStatusIcon(selectedInvoice.status)}</div>
                <span className="font-medium">Stato: {selectedInvoice.status}</span>
              </div>
              {selectedInvoice.client.first_name || selectedInvoice.client.last_name ? (
                <p className="mb-2">
                  <span className="font-medium">Nome:</span>{' '}
                  {`${selectedInvoice.client.first_name} ${selectedInvoice.client.last_name}`}
                </p>
              ) : (
                <p className="mb-2">
                  <span className="font-medium">Nome:</span> {selectedInvoice.client.company_name}
                </p>
              )}
              <p className="mb-2">
                <span className="font-medium">Descrizione:</span> {selectedInvoice.description}
              </p>
              <p className="mb-2">
                <span className="font-medium">Subtotale:</span>{' '}
                {selectedInvoice.subtotal !== null && selectedInvoice.subtotal !== undefined
                  ? selectedInvoice.subtotal.toFixed(2) + '€'
                  : '0.00 €'}
              </p>
              <p>
                <span className="font-medium">Data:</span> {new Date(selectedInvoice.created_at).toLocaleDateString()}
              </p>
            </div>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => getPdfInvoice(selectedInvoice.id)}
                className="bg-fpcred text-white px-4 py-2 rounded-md hover:bg-red-700 transition-colors duration-150">
                Scarica PDF
              </button>
              <button
                onClick={() => getXmlInvoice(selectedInvoice.id)}
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400 transition-colors duration-150">
                Scarica XML
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Documents;
