import { Facebook, Instagram, Mail, Phone, MapPin } from 'lucide-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoFPC } from '../ui/logoFPC.svg';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-gradient-to-r from-fpcpink to-white border-t-4 border-fpcred">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-8">
          <div className="flex flex-col md:flex-row justify-between items-start gap-8">
            {/* Logo e descrizione */}
            <div className="flex flex-col items-center md:items-start space-y-4 md:w-1/4">
              <LogoFPC className="h-16 w-auto" />
              <p className="text-gray-600 text-center md:text-left">
                Formando Percorsi - Ripetizioni per tutti i livelli di istruzione
              </p>
            </div>

            {/* Link utili */}
            <div className="flex flex-col items-center md:items-start space-y-2 md:w-1/4">
              <h3 className="text-fpcred font-bold text-lg mb-2">{t('footer.usefulLinks')}</h3>
              <Link to="/" className="text-gray-600 hover:text-fpcred transition duration-300">
                {t('homeTitle')}
              </Link>
              <Link to="/about" className="text-gray-600 hover:text-fpcred transition duration-300">
                {t('aboutTitle')}
              </Link>
              <Link to="/pricing" className="text-gray-600 hover:text-fpcred transition duration-300">
                {t('pricingTitle')}
              </Link>
              <Link to="/workwithus" className="text-gray-600 hover:text-fpcred transition duration-300">
                {t('workWithUsTitle')}
              </Link>
            </div>

            {/* Contatti */}
            <div className="flex flex-col items-center md:items-start space-y-2 md:w-1/4">
              <h3 className="text-fpcred font-bold text-lg mb-2">{t('footer.contactUs')}</h3>
              <a
                href="mailto:info@formandopercorsi.com"
                className="flex items-center text-gray-600 hover:text-fpcred transition duration-300">
                <Mail className="h-4 w-4 mr-2" />
                info@formandopercorsi.com
              </a>
              <a
                href="tel:+390123456789"
                className="flex items-center text-gray-600 hover:text-fpcred transition duration-300">
                <Phone className="h-4 w-4 mr-2" />
                +39 0123 456789
              </a>
              <div className="flex items-center text-gray-600">
                <MapPin className="h-4 w-4 mr-2" />
                <span>Ferrara, Italia</span>
              </div>
            </div>

            {/* Social */}
            <div className="flex flex-col items-center md:items-start space-y-2 md:w-1/4">
              <h3 className="text-fpcred font-bold text-lg mb-2">{t('footer.followUs')}</h3>
              <div className="flex space-x-4">
                <a
                  href="https://facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-fpcred transition duration-300">
                  <span className="sr-only">Facebook</span>
                  <Facebook className="h-6 w-6" />
                </a>
                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-fpcred transition duration-300">
                  <span className="sr-only">Instagram</span>
                  <Instagram className="h-6 w-6" />
                </a>
              </div>
            </div>
          </div>

          {/* Copyright */}
          <div className="border-t border-gray-200 mt-8 pt-6 text-center md:text-left">
            <p className="text-gray-500 text-sm">
              © {new Date().getFullYear()} Formando Percorsi. {t('footer.allRightsReserved')}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
