import React, { createContext, useContext, useState, ReactNode } from 'react';

interface LoadingContextProps {
  loading: boolean;
  setLoading: (isLoading: boolean, minTime?: number) => void;
}

const LoadingContext = createContext<LoadingContextProps | undefined>(undefined);

export const LoadingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoadingState] = useState(false);
  const [loadingTimeout, setLoadingTimeout] = useState<NodeJS.Timeout | null>(null);

  const setLoading = (isLoading: boolean, minTime = 800) => {
    if (isLoading) {
      setLoadingState(true); // Attiva immediatamente il caricamento
    } else {
      if (loadingTimeout) clearTimeout(loadingTimeout); // Cancella eventuali timeout attivi

      const timeout = setTimeout(() => {
        setLoadingState(false); // Chiude il caricamento dopo il tempo minimo
      }, minTime);

      setLoadingTimeout(timeout);
    }
  };

  return <LoadingContext.Provider value={{ loading, setLoading }}>{children}</LoadingContext.Provider>;
};

export const useLoading = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  return context;
};
