import React, { useEffect, useState } from 'react';
import { EventInput } from '@fullcalendar/core';
import { useLoading } from '../../context/LoadingContext';
import api from '../../utils/api';
import { useError } from '../../context/ErrorContext';

interface Props {
  selectedEvent: EventInput;
  closeModal: () => void;
  handleEditModal: () => void;
}

const EditFamilyLesson: React.FC<Props> = ({ selectedEvent, closeModal, handleEditModal }) => {
  const { setLoading } = useLoading();
  const { setError } = useError();
  const [argomenti, setArgomenti] = useState(selectedEvent.extendedProps.metadata?.topics || '');
  const [confirmDelete, setConfirmDelete] = useState(false);

  const { teacher, subject } = selectedEvent.extendedProps;
  const startDate = selectedEvent.start as Date;
  const dateOnly = startDate.toLocaleDateString();

  // Verifica se la lezione ha lo stato 'modify_request'
  const hasModifyRequest = selectedEvent.extendedProps.status === 'modify_request';

  const currentDate = new Date();
  const diffTime = startDate.getTime() - currentDate.getTime();
  const diffHours = diffTime / (1000 * 3600);
  const isDisabled = diffHours < 48 || hasModifyRequest;

  const handleSaveTopics = async () => {
    try {
      setLoading(true);
      await api.post(`lesson/${selectedEvent.id}/topics`, { topics: argomenti });
    } catch (error) {
      console.error('Errore durante il salvataggio degli argomenti', error);
      setError(true, error.response?.data?.message || 'Errore sconosciuto', error.response?.status || 500);
    } finally {
      //window.location.reload();
      setLoading(false);
    }
  };

  const handleSaveAndClose = async () => {
    closeModal();
    if (argomenti.trim() !== '') {
      try {
        await handleSaveTopics();
      } catch (error) {
        console.error('Errore durante il salvataggio degli argomenti', error);
        return;
      }
    }
  };

  const handleDelete = async () => {
    if (!confirmDelete) {
      setConfirmDelete(true);
      return;
    }
    closeModal();
    try {
      setLoading(true);
      await api.delete(`lesson/${selectedEvent.id}`);
    } catch (error) {
      console.error("Errore durante l'eliminazione della lezione", error);
      setError(true, error.response?.data?.message || 'Errore sconosciuto', error.response?.status || 500);
    } finally {
      //window.location.reload();
      setLoading(false);
    }
  };

  return (
    <div
      className="fixed inset-0 bg-gray-500 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center"
      onClick={handleSaveAndClose}>
      <div
        className="bg-white p-4 gap-2 mx-auto w-96 rounded-lg shadow-lg flex flex-col"
        onClick={(e) => e.stopPropagation()}>
        {!confirmDelete ? (
          <>
            <div className="flex justify-between">
              <h1 className="text-fpcred font-bold text-xl">Informazioni sulla lezione</h1>
              <button
                onClick={handleSaveAndClose}
                className="flex items-center justify-center w-8 h-8 rounded-full border border-fpcred text-fpcred hover:bg-fpcred hover:text-white transition-colors">
                X
              </button>
            </div>
            <div className="flex flex-1 flex-col">
              <p className="font-bold ">{dateOnly}</p>
              <p>Studente: {selectedEvent.title}</p>
              <p>
                Insegnante: {teacher.first_name} {teacher.last_name}
              </p>
              <p>Materia: {subject.name}</p>
              {selectedEvent.extendedProps?.metadata?.link && (
                <a
                  href={selectedEvent.extendedProps.metadata.link}
                  className="font-bold"
                  target="_blank"
                  rel="noopener noreferrer">
                  Link: {selectedEvent.extendedProps.metadata.link}
                </a>
              )}
              <div>
                <label className="block font-bold">Argomenti:</label>
                <textarea
                  className="border p-2 w-full"
                  maxLength={1000}
                  rows={5}
                  value={argomenti}
                  onChange={(e) => {
                    setArgomenti(e.target.value);
                  }}
                  placeholder="Scrivi gli argomenti della lezione..."
                />
              </div>
            </div>
            <div className="flex justify-center gap-4">
              <button
                className={`bg-fpcred text-white font-bold rounded-lg w-full p-2 ${
                  isDisabled ? 'opacity-30 cursor-not-allowed' : ''
                }`}
                onClick={handleDelete}
                disabled={isDisabled}>
                Cancella lezione
              </button>
              <button
                className={`bg-fpcred text-white font-bold rounded-lg w-full p-2 ${
                  isDisabled ? 'opacity-30 cursor-not-allowed' : ''
                }`}
                onClick={handleEditModal}
                disabled={isDisabled}>
                Modifica lezione
              </button>
            </div>
            {isDisabled && (
              <p className="text-fpcred font-bold p-2 bg-fpcpink rounded-lg text-center text-sm">
                {hasModifyRequest
                  ? 'Non è possibile modificare o cancellare una lezione con richiesta di modifica in corso. Prima accetta o rifiuta la richiesta.'
                  : 'Non è possibile modificare una lezione con meno di 48 ore di anticipo'}
              </p>
            )}
          </>
        ) : (
          <div className="flex flex-col items-center gap-4">
            <h2 className="text-fpcred font-bold text-xl">Sei sicuro di voler cancellare la lezione?</h2>
            <div className="flex justify-center gap-4">
              <button
                className="bg-fpcred text-white font-bold rounded-lg w-full p-2"
                onClick={() => setConfirmDelete(false)}>
                Annulla
              </button>
              <button className="bg-fpcpink text-fpcred font-bold rounded-lg w-full p-2" onClick={handleDelete}>
                Conferma
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditFamilyLesson;
