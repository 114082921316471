import React, { useEffect, useState } from 'react';
import { ReactComponent as Creafattura } from '../../ui/CreaFattura.svg';
import { ReactComponent as Pagamenti } from '../../ui/Pagamenti.svg';
import { ReactComponent as FattureRicevute } from '../../ui/FattureRicevute.svg';
import { ReactComponent as FattureInviate } from '../../ui/FattureInviate.svg';
import InvoiceCreator from '../../components/teacher/InvoiceCreator';
import Invoices from '../../components/teacher/Invoices';
import withAuth from '../../utils/IsAuth';
import NotificationsBar from '../../components/NotificationsBar';

export type TypeComponent = 'InvoiceCreator' | 'Payments' | 'ReceivedInvoices' | 'ActiveInvoices';

const TeacherAdministrative: React.FC = () => {
  const [activeComponent, setActiveComponent] = useState<TypeComponent>('InvoiceCreator');

  const renderComponent = (component: TypeComponent) => {
    switch (component) {
      case 'InvoiceCreator':
        return <InvoiceCreator redirect={setActiveComponent} />;
      case 'Payments':
        return (
          <div>
            {/* Content for Pagamenti */}
            <h2>Pagamenti</h2>
          </div>
        );
      case 'ReceivedInvoices':
        return (
          <div>
            {/* Content for Fatture Ricevute */}
            <Invoices type="passive" />
          </div>
        );
      case 'ActiveInvoices':
        return <Invoices type="active" />;
      default:
        return null;
    }
  };

  return (
    <div className="h-full flex flex-col gap-y-4">
      <div>
        <NotificationsBar page="Le mie fatture" />
      </div>
      <div className="flex flex-col rounded-xl bg-white px-4 gap-4 w-full min-h-[600px] overflow-auto">
        <div className="flex justify-around p-2 gap-4">
          <div className="w-1/4 max-w-20 h-full cursor-pointer" onClick={() => setActiveComponent('InvoiceCreator')}>
            <Creafattura className={activeComponent === 'InvoiceCreator' ? 'text-fpcred' : 'text-gray-500'} />
          </div>
          <div className="w-1/4 h-full max-w-20 cursor-pointer hidden" onClick={() => setActiveComponent('Payments')}>
            <Pagamenti className={activeComponent === 'Payments' ? 'text-fpcred' : 'text-gray-500'} />
          </div>
          <div className="w-1/4 h-full max-w-20 cursor-pointer" onClick={() => setActiveComponent('ReceivedInvoices')}>
            <FattureRicevute className={activeComponent === 'ReceivedInvoices' ? 'text-fpcred' : 'text-gray-500'} />
          </div>
          <div className="w-1/4 h-full max-w-20 cursor-pointer" onClick={() => setActiveComponent('ActiveInvoices')}>
            <FattureInviate className={activeComponent === 'ActiveInvoices' ? 'text-fpcred' : 'text-gray-500'} />
          </div>
        </div>
        {renderComponent(activeComponent)}
      </div>
    </div>
  );
};

export default withAuth(TeacherAdministrative);
