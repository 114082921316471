import React, { useEffect, useState } from 'react';
import { SubjectsAndSchools, TeacherForm, Schools } from '../../model/registration/TeacherForm';
import { loadSvg } from '../../utils/loadSvg';
import api from '../../utils/api';
import { useLoading } from '../../context/LoadingContext';
import { useError } from '../../context/ErrorContext';

interface Props {
  form: TeacherForm;
  onUpdateSubjectsAndSchools: (subjectsAndSchools: SubjectsAndSchools[]) => void;
  initialSubjectsAndSchools: SubjectsAndSchools[];
  setHideButtons: (hide: boolean) => void;
}

interface Subjects {
  subject: SubjectDetails;
  schools: LevelSchools[];
}

interface SubjectDetails {
  id: number;
  name: string;
}

interface LevelSchools {
  [school_level: string]: {
    [school_type: string]: SchoolItem[];
  };
}

interface SchoolItem {
  id: number;
  name: string;
  level: string;
  school_type: string;
  classes: string[];
}

type step = 'chooseSubject' | 'chooseSchool';

// Funzione per ottenere la descrizione del livello
const getLevelDescription = (levelName: string) => {
  switch (levelName) {
    case 'hs':
      return 'Scuole Superiori';
    case 'ms':
      return 'Scuole Medie';
    case 'ps':
      return 'Scuole Elementari';
    default:
      return levelName;
  }
};

// Funzione per ottenere il nome del tipo di scuola
const getSchoolTypeName = (schoolTypeName: string) => {
  switch (schoolTypeName) {
    case 'liceo':
      return 'Licei';
    case 'ist_prof':
      return 'Istituto Professionale';
    case 'ist_tec':
      return 'Istituto Tecnico';
    case 'scuola_media':
      return 'Scuola Media';
    case 'scuola_elementare':
      return 'Scuola Elementare';
    default:
      return schoolTypeName;
  }
};

const TeacherSubjectRegistration: React.FC<Props> = (props) => {
  const [images, setImages] = useState<{ [key: string]: string }>({});
  const [subjects, setSubjects] = useState<Subjects[]>([]);
  const { setLoading } = useLoading();
  const { setError } = useError();
  const [step, setStep] = useState<step>('chooseSubject');
  const [selectedSubject, setSelectedSubject] = useState<Subjects | null>(null);
  const [subjectAndSchools, setSubjectAndSchools] = useState<SubjectsAndSchools[]>(
    props.initialSubjectsAndSchools || [] // Usa i dati iniziali se forniti
  );
  const [selectedClasses, setSelectedClasses] = useState<{ [key: string]: string[] }>({});

  useEffect(() => {
    setLoading(true);
    api
      .get('/subject')
      .then((response) => {
        const data = response.data;
        const subjectsArray: Subjects[] = data.subjects;
        setSubjects(subjectsArray);
        loadSubjectImages(subjectsArray);
        console.log('Materie caricate:', subjectsArray);
      })
      .catch((error) => {
        console.error('Errore durante il caricamento delle materie:', error);
        setError(true, error.response?.data?.message || 'Errore sconosciuto', error.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setError]);

  const loadSubjectImages = async (subjectsData: Subjects[]) => {
    const newImages: { [key: string]: string } = {};
    for (const subjectItem of subjectsData) {
      try {
        newImages[subjectItem.subject.name] = await loadSvg(subjectItem.subject.name);
      } catch (error) {
        console.error(`Errore nel caricamento dell'immagine per ${subjectItem.subject.name}:`, error);
      }
    }
    setImages(newImages);
  };

  useEffect(() => {
    if (selectedSubject) {
      console.log('Materia selezionata aggiornata:', selectedSubject);
    }
  }, [selectedSubject]);

  const cleanEmptySchools = (subjects: SubjectsAndSchools[]): SubjectsAndSchools[] => {
    return subjects.map((subject) => ({
      ...subject,
      schools: subject.schools.filter((school) => school.classes.length > 0), // Mantieni solo scuole con anni
    }));
  };

  const handleSelectSubject = (subject: Subjects) => {
    setSelectedSubject(subject);

    setSubjectAndSchools((prev) => {
      // Trova se la materia esiste già
      const existingSubject = prev.find((item) => item.id === subject.subject.id);

      if (existingSubject) {
        // La materia esiste già, nessun duplicato
        return prev;
      }

      // Aggiungi la materia solo se non esiste
      return [...prev, { id: subject.subject.id, schools: [] }];
    });

    setStep('chooseSchool');
    props.setHideButtons(true); // Nasconde i pulsanti
  };

  const handleSelectYears = (schoolId: string, schoolClass: string) => {
    if (!selectedSubject) return; // Assicurati che ci sia una materia selezionata

    setSubjectAndSchools((prev) => {
      // Trova la materia attualmente selezionata
      const subjectIndex = prev.findIndex((item) => item.id === selectedSubject?.subject.id);

      let updatedSubjects = [...prev];

      if (subjectIndex === -1) {
        // Se la materia non esiste, aggiungila
        updatedSubjects.push({
          id: selectedSubject?.subject.id,
          schools: [{ id: schoolId, classes: [schoolClass] }],
        });
      } else {
        // Aggiorna le scuole della materia selezionata
        const updatedSchools = [...updatedSubjects[subjectIndex].schools];
        const schoolIndex = updatedSchools.findIndex((school) => school.id === schoolId);

        if (schoolIndex === -1) {
          // Se la scuola non esiste, aggiungila
          updatedSchools.push({
            id: schoolId,
            classes: [schoolClass],
          });
        } else {
          // Modifica le classi della scuola esistente
          const updatedClasses = new Set(updatedSchools[schoolIndex].classes);
          if (updatedClasses.has(schoolClass)) {
            updatedClasses.delete(schoolClass); // Deseleziona se già selezionato
          } else {
            updatedClasses.add(schoolClass); // Seleziona se non presente
          }
          updatedSchools[schoolIndex].classes = Array.from(updatedClasses);
        }

        // Aggiorna la materia
        updatedSubjects[subjectIndex].schools = updatedSchools;
      }

      // Filtra le scuole vuote
      return cleanEmptySchools(updatedSubjects);
    });

    // Aggiorna lo stato `selectedClasses` per il colore dei pulsanti
    setSelectedClasses((prev) => {
      const currentClasses = prev[schoolId] || [];
      const isSelected = currentClasses.includes(schoolClass);

      return {
        ...prev,
        [schoolId]: isSelected
          ? currentClasses.filter((cls) => cls !== schoolClass) // Deseleziona
          : [...currentClasses, schoolClass], // Seleziona
      };
    });
  };

  const handleSelectAllYears = (schoolId: string, classes: string[]) => {
    if (!selectedSubject) return; // Assicurati che ci sia una materia selezionata

    setSubjectAndSchools((prev) => {
      const subjectIndex = prev.findIndex((item) => item.id === selectedSubject?.subject.id);

      let updatedSubjects = [...prev];

      if (subjectIndex === -1) {
        // Se la materia non esiste, aggiungila
        updatedSubjects.push({
          id: selectedSubject?.subject.id,
          schools: [{ id: schoolId, classes: classes }],
        });
      } else {
        // Aggiorna le scuole della materia selezionata
        const updatedSchools = [...updatedSubjects[subjectIndex].schools];
        const schoolIndex = updatedSchools.findIndex((school) => school.id === schoolId);

        if (schoolIndex === -1) {
          // Se la scuola non esiste, aggiungila
          updatedSchools.push({
            id: schoolId,
            classes: classes,
          });
        } else {
          // Controlla se tutte le classi sono selezionate
          const isAllSelected = classes.every((cls) => updatedSchools[schoolIndex].classes.includes(cls));

          // Aggiorna le classi: seleziona tutte o deseleziona tutte
          updatedSchools[schoolIndex].classes = isAllSelected ? [] : classes;
        }

        // Aggiorna la materia
        updatedSubjects[subjectIndex].schools = updatedSchools;
      }

      // Filtra le scuole vuote
      return cleanEmptySchools(updatedSubjects);
    });

    // Aggiorna lo stato `selectedClasses` per il colore dei pulsanti
    setSelectedClasses((prev) => {
      const currentClasses = prev[schoolId] || [];
      const isAllSelected = classes.every((cls) => currentClasses.includes(cls));

      return {
        ...prev,
        [schoolId]: isAllSelected ? [] : classes,
      };
    });
  };

  useEffect(() => {
    console.log('SubjectAndSchools aggiornato:', subjectAndSchools);
  }, [subjectAndSchools]);

  useEffect(() => {
    if (selectedSubject) {
      // Ricostruisci `selectedClasses` dalla materia selezionata in `subjectAndSchools`
      const existingSubject = subjectAndSchools.find((item) => item.id === selectedSubject.subject.id);

      const initialSelectedClasses: { [key: string]: string[] } = {};

      if (existingSubject) {
        existingSubject.schools.forEach((school) => {
          initialSelectedClasses[school.id] = school.classes;
        });
      } else {
        // Se non c'è, resetta lo stato
        Object.keys(selectedSubject.schools).forEach((schoolLevel) => {
          Object.keys(selectedSubject.schools[schoolLevel]).forEach((schoolType) => {
            selectedSubject.schools[schoolLevel][schoolType].forEach((school) => {
              initialSelectedClasses[school.id] = [];
            });
          });
        });
      }

      setSelectedClasses(initialSelectedClasses); // Aggiorna lo stato visivo
    }
  }, [selectedSubject, subjectAndSchools]);

  useEffect(() => {
    // Filtra le scuole vuote prima di inviare al genitore
    const cleanedSubjects = cleanEmptySchools(subjectAndSchools);
    props.onUpdateSubjectsAndSchools(cleanedSubjects);

    console.log('SubjectAndSchools inviato al genitore:', cleanedSubjects);
  }, [subjectAndSchools]); // Solo `subjectAndSchools` come dipendenza

  const handleBack = () => {
    setSelectedSubject(null);
    setStep('chooseSubject');
    props.setHideButtons(false); // Mostra di nuovo i pulsanti
  };

  return (
    <>
      {step === 'chooseSubject' && (
        <div className="bg-white p-4 rounded-md w-full grid grid-cols-2 md:grid-cols-4 gap-4 overflow-y-auto justify-center">
          {subjects.map((subjectInfo) => {
            const activeSubject = subjectAndSchools.find((item) => item.id === subjectInfo.subject.id);
            const activeSchoolsCount = activeSubject
              ? activeSubject.schools.filter((school) => school.classes.length > 0).length
              : 0;

            return (
              <div
                key={subjectInfo.subject.id}
                onClick={() => {
                  handleSelectSubject(subjectInfo);
                }}
                className="p-4 border rounded-lg bg-gray-50 shadow-sm h-32 min-w-32 flex flex-col items-center justify-center cursor-pointer relative">
                <img src={images[subjectInfo.subject.name]} alt={subjectInfo.subject.name} className="w-16 h-16" />
                <h3 className="font-semibold text-center">{subjectInfo.subject.name}</h3>
                {activeSchoolsCount > 0 && (
                  <span className="absolute top-1 right-1 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                    {activeSchoolsCount}
                  </span>
                )}
              </div>
            );
          })}
        </div>
      )}
      {step === 'chooseSchool' && selectedSubject && (
        <div className="bg-white p-4 rounded-md w-full">
          <div>
            <h2 className="text-xl font-semibold">Scegli le scuole e gli anni</h2>
            <div>
              <h3 className="font-bold text-fpcred">{selectedSubject.subject.name}</h3>
              {Object.keys(selectedSubject.schools).map((schoolLevel) => (
                <div className="flex flex-col gap-y-2" key={schoolLevel}>
                  <h4>{getLevelDescription(schoolLevel)}</h4>
                  {Object.keys(selectedSubject.schools[schoolLevel]).map((schoolType) => (
                    <div className=" border-2 border-gray p-2 rounded-md shadow-md ml-4" key={schoolType}>
                      <h5>{getSchoolTypeName(schoolType)}</h5>
                      {selectedSubject.schools[schoolLevel][schoolType].map((school) => (
                        <div className="ml-4" key={school.id}>
                          <h6>{school.name}</h6>
                          <div className="flex gap-4">
                            {school.classes.map((schoolClass) => (
                              <button
                                className={`py-2 px-4 rounded-md ${
                                  selectedClasses[school.id]?.includes(schoolClass)
                                    ? 'bg-fpcred text-white'
                                    : 'bg-fpcwhite'
                                }`}
                                key={schoolClass}
                                onClick={() => handleSelectYears(school.id, schoolClass)}>
                                {schoolClass}
                              </button>
                            ))}
                            <button
                              className={`py-1 px-2 rounded-md ${
                                selectedClasses[school.id]?.length === school.classes.length
                                  ? 'bg-fpcpink border-2 border-gray-400 text-fpcred'
                                  : 'bg-white border-2 border-gray-400 text-black'
                              }`}
                              onClick={() => handleSelectAllYears(school.id, school.classes)}>
                              {selectedClasses[school.id]?.length === school.classes.length
                                ? 'Deseleziona tutti'
                                : 'Seleziona tutti'}
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className="flex w-full justify-center items-center mt-2">
            <button className="p-4 text-white font-bold bg-fpcred rounded-md " onClick={() => handleBack()}>
              Conferma scuole
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default TeacherSubjectRegistration;
