import React, { useEffect, useState } from 'react';
import Modal from '../../components/modal/Modal';
import withAuth from '../../utils/IsAuth';
import BookLesson from '../../components/BookLesson';
import api from '../../utils/api';
import { Subject } from '../../model/registration/SchoolSelection';
import { useLoading } from '../../context/LoadingContext';
import { useError } from '../../context/ErrorContext';
import { useToast } from '../../context/ToastContext';
import { EventInput } from '@fullcalendar/core';
import FamilyCalendar from '../../components/calendar/FamilyCalendar';
import EditFamilyLesson from '../../components/modal/EditFamilyLesson';
import EditBookedLesson from '../../components/EditBookedLesson';
import NotificationsBar from '../../components/NotificationsBar';
import { BookOpenText, MapPin, Clock, Menu } from 'lucide-react';

interface Props {}

interface Lesson {
  id: number;
  start_time: string;
  end_time: string;
  location: string;
  subject: Subject;
  teacher_id: number;
  student_id: number;
  size: number;
  status: string;
  lesson_order_id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  student: {
    first_name: string;
    last_name: string;
  };
  teacher: {
    first_name: string;
    last_name: string;
  };
}

interface Notification {
  id: number;
  type: string;
  status: string;
}

type ModalContent = 'BookLesson' | 'EditBookedLesson' | null;

const FamilyHome: React.FC<Props> = () => {
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [archivedLessons, setArchivedLessons] = useState<Lesson[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const { setLoading } = useLoading();
  const { setError } = useError();
  const { showToast } = useToast();
  // Per il calendario in desktop, vogliamo tutte le lezioni (attive + passate)
  const [lessonsEvent, setLessonsEvent] = useState<EventInput[]>([]);
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<EventInput | null>(null);
  const [modalContent, setModalContent] = useState<ModalContent>(null);
  const [pendingNotification, setPendingNotification] = useState<boolean>(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [showLessonDeletedModal, setShowLessonDeletedModal] = useState(false);
  // Stato per il toggle delle lezioni passate in mobile
  const [showArchived, setShowArchived] = useState(false);

  const openModal = () => {
    setModalContent('BookLesson');
    setIsOpen(true);
  };

  const closeModal = () => {
    setModalContent(null);
    setIsOpen(false);
  };

  const handleLessonClick = (lesson: Lesson) => {
    // Verifica se la lezione è passata
    const lessonDate = new Date(lesson.start_time);
    const now = new Date();
    
    if (lessonDate < now) {
      // Se la lezione è passata, mostra un toast invece di aprire la modale
      showToast('Non è possibile modificare una lezione già passata.', 'warning');
      return;
    }
    
    const lessonEvent = lessonsEvent.find((event) => event.id === lesson.id.toString());
    setSelectedEvent(lessonEvent);
    setEditModalOpened(true);
  };

  const closeLessionModal = () => {
    setEditModalOpened(false);
  };

  const handleEditModal = () => {
    closeLessionModal();
    setModalContent('EditBookedLesson');
    setIsOpen(true);
  };

  const openEditModal = (event: EventInput) => {
    // Verifica se la lezione è passata
    const lessonDate = new Date(event.start as string | Date);
    const now = new Date();
    
    if (lessonDate < now) {
      // Se la lezione è passata, mostra un toast invece di aprire la modale
      showToast('Non è possibile modificare una lezione già passata.', 'warning');
      return;
    }
    
    setSelectedEvent(event);
    setModalContent('EditBookedLesson');
    setEditModalOpened(true);
  };

  useEffect(() => {
    setLoading(true);
    api
      .get('lesson', {
        params: {
          page: 1, // Prima pagina
          page_size: 100, // Numero di elementi per pagina
        },
      })
      .then((response) => {
        const res = response.data.lessons;
        console.log('Tutte le lezioni:', res);
        // Filtra le lezioni con stato diverso da "deleted" o "placeholder"
        const filteredLessons = res.filter(
          (lesson: Lesson) =>
            lesson.status.trim().toLowerCase() !== 'deleted' && lesson.status.trim().toLowerCase() !== 'placeholder'
        );
        // Imposta la data di oggi (inizio giornata)
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        // Lezioni attive: con data di inizio maggiore o uguale a oggi
        const activeLessons = filteredLessons.filter((lesson: Lesson) => new Date(lesson.start_time) >= today);
        // Lezioni passate (archiviate): con data di inizio precedente a oggi
        const pastLessons = filteredLessons.filter((lesson: Lesson) => new Date(lesson.start_time) < today);
        setLessons(activeLessons);
        setArchivedLessons(pastLessons);

        // Per il calendario in desktop vogliamo TUTTE le lezioni:
        const allLessons = filteredLessons;
        const lessonsCalendar: EventInput[] = allLessons.map((lesson) => ({
          id: lesson.id.toString(),
          title: `${lesson.student.first_name} ${lesson.student.last_name}`,
          start: new Date(lesson.start_time),
          end: new Date(lesson.end_time),
          display: 'block',
          classNames: ['cursor-pointer', 'bg-green-200', 'border-green-500'],
          price: lesson.price,
          extendedProps: {
            student: lesson.student,
            teacher: lesson.teacher,
            subject: lesson.subject,
            location: lesson.location,
            metadata: lesson.metadata,
            status: lesson.status,
            customHtml: (
              <div className="flex flex-col text-black font-bold mt-1">
                <p>
                  {lesson.teacher.first_name} {lesson.teacher.last_name}
                </p>
                <p>
                  {lesson.subject.name} - {lesson.location}
                </p>
                <p>
                  {lesson.start_time.split(' ')[1].split(':')[0]}:{lesson.start_time.split(' ')[1].split(':')[1]} -{' '}
                  {lesson.end_time.split(' ')[1].split(':')[0]}:{lesson.end_time.split(' ')[1].split(':')[1]}
                </p>
              </div>
            ),
          },
        }));
        setLessonsEvent(lessonsCalendar);
      })
      .catch((error) => {
        console.error('Error fetching lessons:', error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleDateClick = (arg: { date: Date }) => {
    console.log('Data selezionata:', arg.date);
    openModal();
  };

  useEffect(() => {
    api
      .get<{ notifications: Notification[] }>('/notification')
      .then((response) => {
        const hasUnreadModifyRequest = response.data.notifications.some(
          (notification) =>
            notification.type === 'lesson_modify_requested_from_teacher_family' &&
            notification.status !== 'rejected' &&
            notification.status !== 'accepted'
        );
        if (hasUnreadModifyRequest) {
          setPendingNotification(true);
          setShowNotificationModal(true);
        }
        const hasLessonDeleted = response.data.notifications.some(
          (notification) =>
            notification.type === 'single_lesson_deleted_from_teacher_family' && notification.status !== 'read'
        );
        if (hasLessonDeleted) {
          setShowLessonDeletedModal(true);
        }
      })
      .catch((error) => {
        console.error('Errore nel recupero delle notifiche', error);
        setError(
          true,
          error.response?.data?.message || 'Errore nel recupero delle notifiche',
          error.response?.status || 500
        );
      });
  }, [setError]);

  // Componente per la card della lezione
  function LessonCard({ lesson, onClick }: { lesson: Lesson; onClick: () => void }) {
    const startDate = new Date(lesson.start_time);
    const endDate = new Date(lesson.end_time);
    const dayOfWeek = startDate.toLocaleDateString('it-IT', { weekday: 'short' });
    const dayOfMonth = startDate.getDate();
    const startTime = startDate.toLocaleTimeString('it-IT', {
      hour: '2-digit',
      minute: '2-digit',
    });
    const endTime = endDate.toLocaleTimeString('it-IT', {
      hour: '2-digit',
      minute: '2-digit',
    });

    // Verifica se la lezione è oggi
    const today = new Date();
    const isToday =
      startDate.getDate() === today.getDate() &&
      startDate.getMonth() === today.getMonth() &&
      startDate.getFullYear() === today.getFullYear();

    return (
      <div
        onClick={onClick}
        className={`flex items-center bg-fpcwhite p-2 justify-between rounded-md shadow-md w-full cursor-pointer relative ${
          isToday ? 'border-2 border-dashed border-fpcred' : ''
        }`}>
        {isToday && (
          <div className="absolute -top-2 -left-1 bg-fpcred text-white text-xs px-2 py-0.5 rounded-md">oggi</div>
        )}
        {/* Sezione sinistra: giorno */}
        <div className="font-bold text-fpcred text-center border-gray-300 w-1/5 border-r-2 flex-shrink-0">
          <div className="text-xs">{dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1, 3)}</div>
          <div className="text-2xl">{dayOfMonth}</div>
        </div>
        {/* Sezione centrale: info lezione */}
        <div className="flex items-center ml-2 flex-grow overflow-hidden">
          <div className="flex flex-col gap-y-1 mr-1 flex-shrink-0">
            <div className="w-5 h-5 flex justify-center items-center">
              <BookOpenText className="w-4 h-4 text-gray-400" />
            </div>
            <div className="h-5 w-5 flex justify-center items-center">
              <Clock className="w-4 h-4 text-gray-400" />
            </div>
            <div className="w-5 h-5 flex justify-center items-center">
              <MapPin className="w-4 h-4 text-gray-400" />
            </div>
          </div>
          <div className="flex flex-col gap-y-1 overflow-hidden">
            <div className="font-bold text-gray-800 text-sm truncate">{lesson.subject.name}</div>
            <div className="text-gray-800 text-sm">
              {startTime} - {endTime}
            </div>
            <div className="text-gray-800 text-sm truncate">{lesson.location}</div>
          </div>
        </div>
        <div className="text-gray-400 items-center justify-center flex h-full flex-shrink-0 ml-1">
          <Menu className="w-5 h-5" />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full h-full max-h-[calc(100vh-200px)] md:max-h-[900px]">
      <div className="mb-2 flex-shrink-0">
        <NotificationsBar page="Le tue lezioni" />
      </div>

      {/* VERSIONE DESKTOP: rimane invariata e mostra TUTTE le lezioni nel calendario */}
      <div className="hidden lg:block p-2 sm:p-4 bg-white rounded-lg h-full">
        <div className="flex flex-col items-center justify-center gap-y-4 h-full">
          <div>
            <button className="py-2 px-3 bg-fpcred text-white rounded-lg" onClick={openModal}>
              Nuova lezione
            </button>
          </div>
          <div className="flex flex-col gap-y-3 w-full bg-white p-4 rounded-lg h-5/6">
            <FamilyCalendar lessonsEvent={lessonsEvent} isOpenEditModal={openEditModal} onDateClick={handleDateClick} />
          </div>
        </div>
      </div>

      {/* VERSIONE MOBILE: mostra solo le lezioni attive e tramite toggle le lezioni passate */}
      <div className="lg:hidden h-full overflow-hidden">
        {/* Contenitore bianco principale con gestione dello scroll */}
        <div
          className="max-w-5xl p-4 bg-white rounded-xl mx-2 sm:mx-auto 
                  h-full flex flex-col overflow-hidden">
          {/* Header / pulsante "Nuova lezione" - non scrollabile */}
          <div className="flex justify-center mb-4 flex-shrink-0">
            <button className="py-2 px-4 bg-fpcred rounded-lg text-white font-bold" onClick={openModal}>
              Nuova lezione
            </button>
          </div>

          {/* Contenitore scrollabile per le lezioni attive */}
          <div className="flex-grow overflow-hidden flex flex-col">
            <h3 className="font-bold text-gray-700 mb-2 flex-shrink-0">Lezioni prenotate</h3>
            <div className="overflow-y-auto flex-grow">
              {lessons.length === 0 ? (
                <div className="border-2 rounded-md text-center text-gray-700 py-4">Non ci sono lezioni prenotate</div>
              ) : (
                <div className="space-y-2 pb-2">
                  {lessons.map((lesson: Lesson) => (
                    <LessonCard key={lesson.id} lesson={lesson} onClick={() => handleLessonClick(lesson)} />
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Sezione lezioni passate (con toggle) */}
          {archivedLessons.length > 0 && (
            <div className="mt-4 flex-shrink-0">
              <button
                className="py-2 px-4 bg-gray-200 text-gray-800 rounded w-full mb-2"
                onClick={() => setShowArchived(!showArchived)}>
                {showArchived ? 'Nascondi lezioni passate' : 'Mostra lezioni passate'}
              </button>

              {showArchived && (
                <div className="overflow-y-auto max-h-[200px]">
                  <div className="space-y-2 pb-2">
                    {archivedLessons.map((lesson: Lesson) => (
                      <LessonCard key={lesson.id} lesson={lesson} onClick={() => handleLessonClick(lesson)} />
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {editModalOpened && (
        <EditFamilyLesson
          selectedEvent={selectedEvent}
          closeModal={closeLessionModal}
          handleEditModal={handleEditModal}
        />
      )}
      <Modal
        open={isOpen}
        closeModal={closeModal}
        title={modalContent === 'BookLesson' ? 'Prenota Lezione' : 'Modifica Lezione'}>
        {modalContent === 'BookLesson' && <BookLesson closeModal={closeModal} />}
        {modalContent === 'EditBookedLesson' && selectedEvent && (
          <EditBookedLesson event={selectedEvent} closeModal={closeModal} />
        )}
      </Modal>
      {/* Modale di notifica */}
      {showNotificationModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 max-w-md relative text-center">
            <h2 className="text-lg font-bold text-fpcred">Notifica Importante</h2>
            <p className="mt-2 text-gray-700">
              Un insegnante ha richiesto una <span className="font-bold">modifica</span> a una lezione. Controlla le
              notifiche.
            </p>
            <button
              className="mt-4 bg-fpcpink text-fpcred font-bold px-4 py-2 rounded-md hover:bg-fpcred hover:text-white transition-all"
              onClick={() => setShowNotificationModal(false)}>
              Chiudi
            </button>
          </div>
        </div>
      )}
      {/* Modale per cancellazione lezione */}
      {showLessonDeletedModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 max-w-md relative text-center">
            <h2 className="text-lg font-bold text-fpcred">Lezione Cancellata</h2>
            <p className="mt-2 text-gray-700">
              Un insegnante ha <span className="font-bold">cancellato</span> una delle lezioni prenotate. Controlla le
              notifiche per maggiori dettagli.
            </p>
            <button
              className="mt-4 bg-fpcpink text-fpcred font-bold px-4 py-2 rounded-md hover:bg-fpcred hover:text-white transition-all"
              onClick={() => setShowLessonDeletedModal(false)}>
              Chiudi
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default withAuth(FamilyHome);
