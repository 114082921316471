import React, { useEffect, useState } from 'react';
import EditProfile from '../../components/profile/EditProfile';
import ChangePassword from '../../components/profile/ChangePassword';
import AddFamilyMember, { FamilyMember } from '../../components/profile/AddFamilyMember';
import withAuth from '../../utils/IsAuth';
import api from '../../utils/api';
import { FamilyInfo } from '../../model/FamilyInfo';
import { useLoading } from '../../context/LoadingContext';
import { useError } from '../../context/ErrorContext';
import useAuth from '../../hooks/useAuth';
import { User } from 'lucide-react';
import NotificationsBar from '../../components/NotificationsBar';

export type ProfileComponent = 'EditProfile' | 'ChangePassword' | 'AddFamilyMember';

const Profile: React.FC = () => {
  const [activeComponent, setActiveComponent] = useState<ProfileComponent>('EditProfile');
  const [familyInfo, setFamilyInfo] = useState<FamilyInfo | null>(null);
  const [familyMember, setFamilyMember] = React.useState<FamilyMember[]>([]);
  const { setLoading } = useLoading();
  const { setError } = useError();
  const { auth, logout } = useAuth();

  useEffect(() => {
    setLoading(true);
    api
      .get('/family')
      .then((response) => {
        setFamilyInfo(response.data.user);
        setFamilyMember(response.data.sons);
      })
      .catch((error) => {
        console.error('Error fetching family info:', error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleShowComponent = (component: ProfileComponent) => {
    setActiveComponent(component); // Cambia il componente attivo
    setLoading(false); // Disattiva il caricamento
  };

  if (!familyInfo) return null;

  return (
    <div className="flex flex-col bg-fpcpink h-full p-2 gap-y-4">
      <div className="mb-2">
        <NotificationsBar page="Il tuo profilo" />
      </div>

      <div className="bg-white  w-full h-full rounded-md shadow-md overflow-auto flex flex-col md:items-start items-center md:flex-row gap-4">
        <div className="flex w-full md:flex-col md:w-1/5 items-center justify-around md:justify-start p-4 gap-4 ">
          <User className="w-20 h-20 sm:w-24 sm:h-24 bg-fpcred rounded-full" />
          <div className="text-center md:text-left flex flex-col items-center">
            <h2 className="flex justify-center font-bold">
              {familyInfo.first_name} {familyInfo.last_name}
            </h2>
            <div className="flex lg:hidden justify-center">
              {auth.isAuth && (
                <div onClick={logout} className="flex flex-row items-center mb-10 justify-center cursor-pointer">
                  <img
                    src="https://icongr.am/material/logout.svg?size=64&color=FF5757"
                    alt="logout-icon"
                    className="w-8 h-8"
                  />
                  <span className="text-fpcred font-bold">Esci</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full md:mx-auto items-center p-2">
          <div className="flex w-full md:max-w-96 items-center gap-4 p-1 bg-fpcwhite rounded-md">
            <button
              className={`bg-fpcwhite rounded-xl flex p-1 items-center w-full h-full justify-center ${
                activeComponent === 'EditProfile' ? 'border-2 border-fpcred bg-white shadow-md' : ''
              }`}
              onClick={() => handleShowComponent('EditProfile')}>
              <p className="text-center font-bold">Dati personali</p>
            </button>
            <button
              className={`bg-fpcwhite  rounded-xl  p-1 flex items-center w-full h-full justify-center ${
                activeComponent === 'AddFamilyMember' ? 'border-2 border-fpcred bg-white shadow-md' : ''
              }`}
              onClick={() => handleShowComponent('AddFamilyMember')}>
              <p className="text-center font-bold">Famiglia</p>
            </button>
            <button
              className={`bg-fpcwhite  rounded-xl  p-1 flex items-center w-full h-full justify-center ${
                activeComponent === 'ChangePassword' ? 'border-2 border-fpcred bg-white shadow-md' : ''
              }`}
              onClick={() => handleShowComponent('ChangePassword')}>
              <p className="text-center font-bold">Password</p>
            </button>
          </div>
          <div className="overflow-auto w-full md:max-w-[70%] p-2 flex justify-center">
            {activeComponent === 'EditProfile' && <EditProfile goTo={handleShowComponent} />}
            {activeComponent === 'ChangePassword' && <ChangePassword goTo={handleShowComponent} />}
            {activeComponent === 'AddFamilyMember' && <AddFamilyMember goTo={handleShowComponent} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAuth(Profile);
