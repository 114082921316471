import React, { useEffect, useRef, useState } from 'react';
import EditPersonalProfile from '../../components/teacher/EditPersonalProfile';
import EditTaxInformation from '../../components/teacher/EditTaxInformation';
import ChangePassword from '../../components/teacher/ChangePassword';
import ChangeSubject from '../../components/teacher/ChangeSubject';
import { motion } from 'framer-motion';
import api from '../../utils/api';
import { TeacherInfo } from '../../model/TeacherInfo';
import withAuth from '../../utils/IsAuth';
import useAuth from '../../hooks/useAuth';
import { User } from 'lucide-react';
import { useLoading } from '../../context/LoadingContext';
import { useError } from '../../context/ErrorContext';
import { SubjectInfo } from '../../model/TeacherInfo';
import SchoolsAndSubjectsModal from '../../components/modal/SchoolsAndSubjectsModal';
import AddSubjectModal from '../../components/modal/AddSubjectModal';
import NotificationsBar from '../../components/NotificationsBar';

export type ProfileComponent = 'EditPersonalProfile' | 'ChangePassword' | 'ChangeSubject' | 'EditTaxInformation';

interface form {
  first_name: string;
  last_name: string;
  bio: string;
}

const TeacherProfile: React.FC = () => {
  const [activeComponent, setActiveComponent] = useState<ProfileComponent>('EditPersonalProfile');
  const [teacherInfo, setTeacherInfo] = useState<TeacherInfo>();
  const { auth, logout } = useAuth();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [form, setForm] = useState<form>({
    first_name: '',
    last_name: '',
    bio: '',
  });
  const { setLoading } = useLoading();
  const { setError } = useError();
  const [initialForm, setInitialForm] = useState<form | null>(null);
  const [isFormChanged, setIsFormChanged] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState<{ info: SubjectInfo; imageUrl: string } | null>(null);
  const [isAddSubjectModalOpen, setIsAddSubjectModalOpen] = useState(false);

  const handleOpenAddSubjectModal = () => {
    setIsAddSubjectModalOpen(true);
  };

  const handleCloseAddSubjectModal = () => {
    setIsAddSubjectModalOpen(false);
  };

  const handleOpenModal = (subjectInfo: SubjectInfo, imageUrl: string) => {
    setSelectedSubject({ info: subjectInfo, imageUrl });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedSubject(null);
  };

  useEffect(() => {
    setLoading(true);
    api
      .get('/teacher')
      .then((response) => {
        console.log(response.data.user);
        setTeacherInfo(response.data.user);
        const initialForm = {
          first_name: response.data.user.first_name,
          last_name: response.data.user.last_name,
          bio: response.data.user.bio,
        };
        setForm(initialForm);
        setInitialForm(initialForm);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setError]);

  // Aggiungi useEffect per adattare l'altezza del textarea dopo il caricamento dei dati
  useEffect(() => {
    if (textareaRef.current) {
      handleTextareaInput(); // Chiama la funzione per adattare l'altezza quando i dati vengono caricati
    }
  }, [form.bio]);

  const handleShowComponent = (component: ProfileComponent) => {
    setActiveComponent(component);
  };

  const handleTextareaInput = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Resetta l'altezza prima di calcolare la nuova altezza
      textarea.style.height = 'auto';
      // Calcola la nuova altezza in base allo scrollHeight
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
    handleTextareaInput(); // Aggiorna l'altezza in base al nuovo valore

    // Verifica se il form è stato modificato rispetto all'initialForm
    if (initialForm) {
      setIsFormChanged(
        (name === 'bio' && value.trim() !== '' && value !== initialForm.bio) || // Controlla solo il valore attuale
          (name !== 'bio' && form.first_name !== initialForm.first_name) ||
          (name !== 'bio' && form.last_name !== initialForm.last_name)
      );
    }
  };

  const handleSave = () => {
    setLoading(true);
    // Logica per il salvataggio (esegui la PATCH qui)
    api
      .patch('/teacher', form)
      .then(() => {
        setIsFormChanged(false);
        setInitialForm(form); // Aggiorna l'initialForm con i nuovi dati
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        //window.location.reload();
        setLoading(false);
      });
  };

  if (!teacherInfo) return null;

  return (
    <div className="flex flex-col bg-fpcpink h-full gap-y-4">
      <div>
        <NotificationsBar page="Il mio profilo" />
      </div>

      <div className="bg-white w-full min-h-[500px] rounded-md shadow-md flex flex-col md:flex-row gap-4 p-4">
        {/* Sezione Profilo e Bio */}
        <div className="flex flex-col w-full md:w-2/5 gap-4 justify-around md:justify-normal items-center">
          <div className="flex md:flex-col w-full justify-around items-center">
            <User className="w-20 h-20 sm:w-24 sm:h-24 bg-fpcred rounded-full" />
            <div className="text-center md:text-left flex flex-col items-center">
              <h2 className="font-bold text-xl">
                {form.first_name} {form.last_name}
              </h2>
              {auth.isAuth && (
                <div
                  onClick={logout}
                  className="flex flex-row items-center justify-center md:justify-start cursor-pointer mt-2">
                  <img
                    src="https://icongr.am/material/logout.svg?size=64&color=FF5757"
                    alt="logout-icon"
                    className="w-6 h-6"
                  />
                  <span className="text-fpcred font-bold ml-2">Esci</span>
                </div>
              )}
            </div>
          </div>
          <textarea
            ref={textareaRef}
            name="bio"
            value={form.bio}
            className="border border-gray-300 w-full p-2 min-h-12 rounded-lg resize-none overflow-hidden"
            onInput={handleTextareaInput} // L'aggiornamento automatico rimane intatto
            onChange={handleInputChange}
            rows={1}
          />
          {isFormChanged && (
            <button onClick={handleSave} className="bg-fpcred text-white p-2 rounded-full w-3/4 sm:w-1/2">
              Salva
            </button>
          )}
        </div>

        {/* Sezione dei Pulsanti e Componente attivo */}
        <div className="flex flex-col w-full md:mx-auto items-center">
          <div className="flex w-full md:max-w-96 items-center gap-4 p-1 bg-fpcwhite rounded-md">
            <button
              className={`bg-fpcwhite rounded-xl flex p-1 items-center w-full h-full justify-center ${
                activeComponent === 'EditPersonalProfile' ? 'border-2 border-fpcred bg-white shadow-md' : ''
              }`}
              onClick={() => handleShowComponent('EditPersonalProfile')}>
              <p className="text-center font-bold">Dati personali</p>
            </button>
            <button
              className={`bg-fpcwhite  rounded-xl  p-1 flex items-center w-full h-full justify-center ${
                activeComponent === 'EditTaxInformation' ? 'border-2 border-fpcred bg-white shadow-md' : ''
              }`}
              onClick={() => handleShowComponent('EditTaxInformation')}>
              <p className="text-center font-bold">
                Dati <br /> fiscali
              </p>
            </button>
            <button
              className={`bg-fpcwhite rounded-xl p-1 w-full h-full flex justify-center items-center ${
                activeComponent === 'ChangeSubject' ? 'border-2 border-fpcred bg-white shadow-md' : ''
              }`}
              onClick={() => handleShowComponent('ChangeSubject')}>
              <p className="text-center font-bold">Le mie materie</p>
            </button>
            <button
              className={`bg-fpcwhite rounded-xl p-1 w-full h-full flex justify-center items-center ${
                activeComponent === 'ChangePassword' ? 'border-2 border-fpcred bg-white shadow-md' : ''
              } ${`disabled:cursor-not-allowed`}`}
              onClick={() => handleShowComponent('ChangePassword')}
              disabled={true}>
              <p className="text-center font-bold">
                La mia <br /> password
              </p>
            </button>
          </div>
          {activeComponent !== 'ChangeSubject' && (
            <motion.div
              className="w-full h-full md:max-w-[70%] flex justify-center"
              initial={{ opacity: 0, y: -30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}>
              {activeComponent === 'EditPersonalProfile' && <EditPersonalProfile goTo={handleShowComponent} />}
              {activeComponent === 'ChangePassword' && <ChangePassword goTo={handleShowComponent} />}
              {activeComponent === 'EditTaxInformation' && <EditTaxInformation goTo={handleShowComponent} />}
            </motion.div>
          )}
          <motion.div
            className="w-full h-full flex justify-center"
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}>
            {activeComponent === 'ChangeSubject' && (
              <ChangeSubject
                goTo={handleShowComponent}
                onSelectSubject={handleOpenModal}
                onAddSubject={handleOpenAddSubjectModal}
              />
            )}
          </motion.div>
        </div>
      </div>
      <SchoolsAndSubjectsModal
        open={isModalOpen}
        title={'Scuole attive'}
        closeModal={handleCloseModal}
        selectedSubject={selectedSubject}
        teacherInfo={teacherInfo}
      />
      <AddSubjectModal
        open={isAddSubjectModalOpen}
        title={'Aggiungi materia'}
        closeModal={handleCloseAddSubjectModal}
        teacherInfo={teacherInfo}
      />
    </div>
  );
};

export default withAuth(TeacherProfile);
