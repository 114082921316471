import React from 'react';

const EmailConfirm: React.FC = () => {
  return (
    <div className="flex flex-col p-4 md:mt-40 justify-center items-center">
      <div className="flex flex-col items-center justify-center text-center p-4 bg-white rounded-md shadow-md">
        <h1 className="text-2xl md:text-4xl font-bold mb-4">Registrazione avvenuta con successo!</h1>
        <p className="text-lg md:text-xl mb-8">Ti abbiamo inviato una mail per confermare il tuo indirizzo email.</p>
        <p className="text-sm md:text-base">Se non vedi la mail, controlla nella cartella spam.</p>
      </div>
    </div>
  );
};

export default EmailConfirm;
