import React, { useEffect, useState, useMemo } from 'react';
import { SubjectInfo, TeacherInfo } from '../../model/TeacherInfo';
import api from '../../utils/api';

interface ModalProps {
  open: boolean;
  title?: string;
  closeModal: () => void;
  selectedSubject: { info: SubjectInfo; imageUrl: string } | null;
  teacherInfo: TeacherInfo | undefined;
}

export interface PatchSchool {
  id: number;
  classes: string[];
}

export interface PatchSubject {
  id: number;
  schools: PatchSchool[];
}

export interface PatchTeacherInfo {
  // ... altri campi
  subjects: PatchSubject[];
}

interface Subjects {
  subject: SubjectDetails;
  schools: LevelSchools;
}

interface SubjectDetails {
  id: number;
  name: string;
}

interface LevelSchools {
  [school_level: string]: {
    [school_type: string]: SchoolItem[];
  };
}

interface SchoolItem {
  id: number;
  name: string;
  level: string;
  school_type: string;
  classes: string[];
}

// Funzione per ottenere la descrizione del livello
const getLevelDescription = (levelName: string) => {
  switch (levelName) {
    case 'hs':
      return 'Scuole Superiori';
    case 'ms':
      return 'Scuole Medie';
    case 'ps':
      return 'Scuole Elementari';
    default:
      return levelName;
  }
};

// Funzione per ottenere il nome del tipo di scuola
const getSchoolTypeName = (schoolTypeName: string) => {
  switch (schoolTypeName) {
    case 'liceo':
      return 'Licei';
    case 'ist_prof':
      return 'Istituto Professionale';
    case 'ist_tec':
      return 'Istituto Tecnico';
    case 'scuola_media':
      return 'Scuola Media';
    case 'scuola_elementare':
      return 'Scuola Elementare';
    default:
      return schoolTypeName;
  }
};

// Funzione per ottenere l'elenco degli anni in base al livello
const getYearsArray = (levelName: string) => {
  if (levelName === 'hs') return ['1', '2', '3', '4', '5'];
  if (levelName === 'ms') return ['1', '2', '3'];
  if (levelName === 'ps') return ['1', '2', '3', '4', '5'];
  return [];
};

const SchoolsAndSubjectsModal = ({ open, title, closeModal, selectedSubject, teacherInfo }: ModalProps) => {
  const [showModal, setShowModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modifiedYears, setModifiedYears] = useState<{ [schoolId: number]: string[] }>({});
  const [allSubjects, setAllSubjects] = useState<Subjects[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Fetch subjects
  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await api.get('/subject');
        setAllSubjects(response.data.subjects);
        console.log('Materie:', response.data.subjects);
      } catch (error) {
        console.error('Errore durante il fetch delle materie:', error);
        setErrorMessage('Errore durante il fetch delle materie.');
      }
    };

    fetchSubjects();
  }, []);

  const allSchoolsForSubject = useMemo(() => {
    if (!selectedSubject) return null;
    const subjectData = allSubjects.find((subject) => subject.subject.id === selectedSubject.info.subject.id);
    return subjectData ? subjectData.schools : null;
  }, [selectedSubject, allSubjects]);

  const isSchoolActive = (levelName: string, schoolTypeName: string, schoolId: number) => {
    const activeSchools = selectedSubject?.info.schools;
    if (!activeSchools) return false;
    const schoolsAtLevel = activeSchools[levelName];
    if (!schoolsAtLevel) return false;
    const schoolsAtType = schoolsAtLevel[schoolTypeName];
    if (!schoolsAtType) return false;
    return schoolsAtType.some((school) => school.id === schoolId);
  };

  useEffect(() => {
    if (open) {
      setShowModal(true);
      setTimeout(() => setVisible(true), 10);

      if (allSchoolsForSubject) {
        const initialModifiedYears: { [schoolId: number]: string[] } = {};

        Object.entries(allSchoolsForSubject).forEach(([levelName, schoolTypes]) => {
          Object.entries(schoolTypes).forEach(([schoolTypeName, schools]) => {
            schools.forEach((school) => {
              const activeSchool = selectedSubject?.info.schools[levelName]?.[schoolTypeName]?.find(
                (s) => s.id === school.id
              );
              initialModifiedYears[school.id] = activeSchool ? [...activeSchool.teached_classes] : [];
            });
          });
        });

        setModifiedYears(initialModifiedYears);
      }
    } else {
      setVisible(false);
      const timer = setTimeout(() => setShowModal(false), 500);
      setModifiedYears({});
      return () => clearTimeout(timer);
    }
  }, [open, selectedSubject, allSchoolsForSubject]);

  const handleYearToggle = (schoolId: number, year: string) => {
    setModifiedYears((prev) => {
      const currentYears = prev[schoolId] || [];
      const updatedYears = currentYears.includes(year)
        ? currentYears.filter((y) => y !== year)
        : [...currentYears, year];
      return { ...prev, [schoolId]: updatedYears };
    });
  };

  const preparePatchPayload = (
    teacherInfo: TeacherInfo,
    modifiedYears: { [schoolId: number]: string[] },
    selectedSubjectInfo: SubjectInfo | undefined
  ): PatchTeacherInfo => {
    const existingSubjectsMap: { [subjectId: number]: PatchSubject } = {};

    teacherInfo.subjects.forEach((subject) => {
      const subjectId = subject.subject.id;
      existingSubjectsMap[subjectId] = {
        id: subjectId,
        schools: [],
      };

      Object.values(subject.schools).forEach((schoolTypes) => {
        Object.values(schoolTypes).forEach((schoolsArray) => {
          schoolsArray.forEach((school) => {
            const schoolId = school.id;
            const updatedClasses = modifiedYears[schoolId] || school.teached_classes;
            existingSubjectsMap[subjectId].schools.push({
              id: schoolId,
              classes: updatedClasses,
            });
          });
        });
      });
    });

    // Aggiungi nuove scuole per la materia selezionata
    if (selectedSubjectInfo) {
      const subjectId = selectedSubjectInfo.subject.id;
      if (!existingSubjectsMap[subjectId]) {
        existingSubjectsMap[subjectId] = {
          id: subjectId,
          schools: [],
        };
      }

      const subjectSchools = existingSubjectsMap[subjectId].schools;

      Object.entries(allSchoolsForSubject!).forEach(([levelName, schoolTypes]) => {
        Object.entries(schoolTypes).forEach(([schoolTypeName, schools]) => {
          schools.forEach((school) => {
            const schoolId = school.id;
            const selectedClasses = modifiedYears[schoolId] || [];
            if (selectedClasses.length > 0 && !subjectSchools.some((s) => s.id === schoolId)) {
              subjectSchools.push({
                id: schoolId,
                classes: selectedClasses,
              });
            }
          });
        });
      });
    }

    return {
      // Qui inserisci gli altri campi necessari di teacherInfo
      subjects: Object.values(existingSubjectsMap),
    };
  };

  const handleSaveChanges = async () => {
    if (!teacherInfo) return;

    setIsSaving(true);
    setErrorMessage('');
    const payload = preparePatchPayload(teacherInfo, modifiedYears, selectedSubject?.info);

    console.log('Payload PATCH:', JSON.stringify(payload, null, 2));

    try {
      await api.patch('/teacher', payload);
      console.log('Modifiche salvate con successo');
      // Display success message or feedback
      closeModal();
    } catch (error) {
      console.error('Errore durante il salvataggio:', error);
      setErrorMessage('Errore durante il salvataggio.');
    } finally {
      //window.location.reload();
      setIsSaving(false);
    }
  };

  const handleSelectAllYears = (schoolId: number, years: string[]) => {
    setModifiedYears((prev) => ({
      ...prev,
      [schoolId]: years,
    }));
  };

  const handleClearAllYears = (schoolId: number) => {
    setModifiedYears((prev) => ({
      ...prev,
      [schoolId]: [],
    }));
  };

  return (
    <>
      {showModal && (
        <div
          className={`z-50 fixed inset-0 flex items-end bg-black bg-opacity-50 transition-opacity duration-500 ${
            visible ? 'opacity-100' : 'opacity-0'
          }`}>
          <div
            className={`w-full bg-white rounded-t-lg h-[95%] transition-transform duration-500 ${
              visible ? 'translate-y-0' : 'translate-y-full'
            }`}>
            <div className="flex justify-between items-center pt-2 pr-4">
              <h3 className="text-xl text-fpcred font-semibold pl-4">{title}</h3>
              <button onClick={closeModal} className="cursor-pointer text-3xl text-fpcred font-bold">
                <p>X</p>
              </button>
            </div>
            <div className="overflow-auto p-4 h-[95%]">
              {errorMessage && <p className="text-red-500">{errorMessage}</p>}
              <div className="flex flex-col items-center">
                <img src={selectedSubject?.imageUrl} alt={selectedSubject?.info.subject.name} className="w-20 h-20" />
                <h2 className="text-lg font-semibold mt-2">{selectedSubject?.info.subject.name}</h2>
              </div>

              {/* Visualizzazione delle scuole */}
              {allSchoolsForSubject && (
                <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                  {Object.entries(allSchoolsForSubject).map(([levelName, schoolTypes]) => (
                    <div key={levelName} className="w-full">
                      <h4 className="font-semibold text-lg text-fpcred mb-3">{getLevelDescription(levelName)}</h4>
                      {Object.entries(schoolTypes).map(([schoolTypeName, schools]) => (
                        <div key={schoolTypeName} className="border rounded-lg shadow-lg p-4 bg-gray-100">
                          <h5 className="text-xl font-bold text-gray-700 mb-2">{getSchoolTypeName(schoolTypeName)}</h5>
                          <div className="flex flex-col gap-4">
                            {schools.map((school) => {
                              const active = isSchoolActive(levelName, schoolTypeName, school.id);
                              const allYears = getYearsArray(levelName);
                              const selectedYears = modifiedYears[school.id] || [];
                              const allYearsSelected = selectedYears.length === allYears.length;

                              return (
                                <div
                                  key={school.id}
                                  className={`p-2 bg-white rounded-lg shadow-sm ${
                                    active ? 'border-2 border-green-500' : ''
                                  }`}>
                                  <p className="text-md font-semibold text-gray-800">{school.name}</p>
                                  <p className="text-sm text-gray-600">Anni scolastici:</p>
                                  {/* Aggiungi il pulsante "Seleziona Tutti" */}
                                  <button
                                    onClick={() =>
                                      allYearsSelected
                                        ? handleClearAllYears(school.id)
                                        : handleSelectAllYears(school.id, allYears)
                                    }
                                    className="text-blue-500 underline text-sm mb-2">
                                    {allYearsSelected ? 'Deseleziona Tutti' : 'Seleziona Tutti'}
                                  </button>
                                  <div className="flex gap-2 mt-2">
                                    {allYears.map((year) => (
                                      <button
                                        key={year}
                                        onClick={() => handleYearToggle(school.id, year)}
                                        aria-label={`Toggle year ${year} for school ${school.name}`}
                                        className={`p-1 w-8 h-8 rounded-full border ${
                                          modifiedYears[school.id]?.includes(year)
                                            ? 'border-red-500 border-2 text-red-500'
                                            : 'border-gray-300 text-gray-500'
                                        }`}>
                                        {year}
                                      </button>
                                    ))}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}

              <button
                onClick={handleSaveChanges}
                disabled={isSaving}
                className={`mt-4 bg-fpcred text-white p-2 rounded-md w-full ${
                  isSaving ? 'opacity-50 cursor-not-allowed' : 'hover:bg-fpcred-dark transition-colors'
                }`}>
                {isSaving ? 'Salvataggio...' : 'Salva Modifiche'}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(SchoolsAndSubjectsModal);
