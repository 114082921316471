import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import React, { useEffect, useRef, useState } from 'react';
import interactionPlugin from '@fullcalendar/interaction';
import { EventInput } from '@fullcalendar/core';
import useScreenWidth from '../../hooks/useScreenWidth';
import api from '../../utils/api';
import DaysSlider from '../DaySlider';
import '../../styles/calendarStyles.css';
import { useLoading } from '../../context/LoadingContext';

type Props = {
  event: EventInput;
  handleSlotClick: (start: Date, end: Date) => void;
};

const EditBookedLessonTeacherCalendar: React.FC<Props> = ({ event, handleSlotClick }) => {
  const { setLoading } = useLoading();
  const screenWidth = useScreenWidth();
  const calendarRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [timeSlots, setTimeSlots] = useState<any[]>([]);
  const [lessons, setLessons] = useState<any[]>([]);
  const [slotsGenerated, setSlotsGenerated] = useState(false);

  const lesson = event as EventInput;

  console.log('Lezione:', lesson);

  const today = new Date();
  const start = new Date(new Date().setDate(today.getDate() - 30));
  const maxDate = new Date(new Date().setDate(today.getDate() + 35));

  const handleDateChange = (date) => {
    setSelectedDate(date);

    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(date);
    } else {
      console.error('FullCalendar reference is null');
    }
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([api.get('/lesson'), api.get('/lesson/student', { params: { student_id: lesson.student_id } })])
      .then(([teacherRes, studentRes]) => {
        const teacherLessons = teacherRes.data.lessons
          .filter((lesson) => lesson.status !== 'deleted')
          .map((lesson) => ({
            start: new Date(lesson.start_time),
            end: new Date(lesson.end_time),
            status: lesson.status,
          }));
        const studentLessons = studentRes.data.lessons
          .filter((lesson) => lesson.status !== 'deleted')
          .map((lesson) => ({
            start: new Date(lesson.start_date_time),
            end: new Date(lesson.end_date_time),
          }));
        console.log('Lezioni insegnante:', teacherLessons);
        console.log('Lezioni studente:', studentLessons);

        // Combina i dati
        const combinedLessons = [...teacherLessons, ...studentLessons];

        // Filtra i duplicati: se due lezioni hanno lo stesso orario di inizio e fine, ne prende una sola
        const uniqueLessons = combinedLessons.filter(
          (lesson, index, self) =>
            index ===
            self.findIndex(
              (l) => l.start.getTime() === lesson.start.getTime() && l.end.getTime() === lesson.end.getTime()
            )
        );

        setLessons(uniqueLessons);
      })
      .catch((error) => {
        console.error('Errore nel caricamento delle lezioni:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [lesson]);

  // Funzione per verificare se uno slot creerebbe sovrapposizioni con altre lezioni
  const wouldCreateOverlap = (startTime: Date, endTime: Date) => {
    // Escludiamo la lezione che stiamo modificando dal controllo
    const lessonToModifyTime = new Date(lesson.start as string | number | Date).getTime();

    return lessons.some((booked) => {
      // Ignora la lezione che stiamo modificando
      if (booked.start.getTime() === lessonToModifyTime) {
        return false;
      }

      // Verifica se c'è sovrapposizione
      // (inizio dello slot < fine della lezione prenotata) && (fine dello slot > inizio della lezione prenotata)
      return startTime < booked.end && endTime > booked.start;
    });
  };

  // Funzione per unificare intervalli sovrapposti
  function mergeIntervals(intervals: { start: Date; end: Date }[]): { start: Date; end: Date }[] {
    if (!intervals.length) return [];

    // Ordina gli intervalli in base all'orario di inizio
    intervals.sort((a, b) => a.start.getTime() - b.start.getTime());

    const merged: { start: Date; end: Date }[] = [];
    let current = intervals[0];

    for (let i = 1; i < intervals.length; i++) {
      const interval = intervals[i];

      // Se l'intervallo successivo inizia prima o esattamente dove finisce current,
      // allora sono sovrapposti o adiacenti, quindi li fondiamo
      if (interval.start.getTime() <= current.end.getTime()) {
        current.end = new Date(Math.max(current.end.getTime(), interval.end.getTime()));
      } else {
        // Altrimenti chiudiamo l'intervallo corrente e ne apriamo uno nuovo
        merged.push(current);
        current = interval;
      }
    }
    // Aggiungiamo l'ultimo intervallo rimasto
    merged.push(current);

    return merged;
  }

  const generateTimeSlots = () => {
    const stepMs = 30 * 60000; // 30 minuti in millisecondi
    const slots = [];
    const backgroundSlots = []; // Array per raccogliere tutti gli eventi di background
    // Durata originale della lezione (usata per gli slot liberi)
    const lessonDurationMs = (lesson.size || 1) * 60 * 60000;

    const currentDate = new Date();
    const initialStartTime = new Date(currentDate);
    initialStartTime.setHours(currentDate.getHours() + 2, 0, 0, 0);

    let date = new Date(initialStartTime);
    date.setHours(0, 0, 0, 0);

    while (date <= maxDate) {
      const startTime =
        date.toDateString() === currentDate.toDateString() ? initialStartTime.getTime() : date.getTime();
      const dayEnd = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 24, 0, 0, 0).getTime();

      let currentTime = startTime;

      while (currentTime < dayEnd) {
        const startSlot = new Date(currentTime);
        const endSlot = new Date(currentTime + stepMs);
        const displayEnd = new Date(currentTime + lessonDurationMs);

        // Verifica se esiste una lezione prenotata che copre questo slot
        const occupant = lessons.find(
          (booked) => booked.start.getTime() === startSlot.getTime() && booked.end.getTime() > startSlot.getTime()
        );

        // Verifica se lo slot creerebbe sovrapposizioni con altre lezioni
        const wouldOverlap = wouldCreateOverlap(startSlot, displayEnd);

        if (occupant) {
          // Controlla se l'orario della lezione prenotata corrisponde a quella che stiamo modificando
          const isLessonToModify =
            new Date(lesson.start as string | number | Date).getTime() === occupant.start.getTime();

          if (isLessonToModify) {
            // Genera uno slot di soli 30 minuti per la lezione da modificare
            slots.push({
              id: `occupied-${occupant.start.toISOString()}`,
              start: startSlot,
              end: endSlot,
              displayEnd,
              title: `In modifica ${startSlot.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              })} - ${displayEnd.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              })}`,
              classNames: ['bookedLesson', 'cursor-not-allowed', 'bold-text'],
              textColor: '#000000',
              editable: false,
            });
            currentTime += stepMs;
          } else {
            // Per le altre lezioni prenotate, blocca l'intera durata
            slots.push({
              id: `occupied-${occupant.start.toISOString()}`,
              start: occupant.start,
              end: occupant.end,
              displayEnd: occupant.end,
              title: 'Lezione occupata',
              classNames: ['bookedLesson', 'cursor-not-allowed'],
              textColor: '#000000',
              editable: false,
            });
            currentTime = occupant.end.getTime();
          }
        } else if (wouldOverlap) {
          // Invece di creare subito un evento di background, lo aggiungiamo all'array per unificarli dopo
          backgroundSlots.push({
            start: startSlot,
            end: endSlot,
          });
          currentTime += stepMs;
        } else {
          // Genera slot liberi
          slots.push({
            id: `${date.toISOString()}-${currentTime}`,
            start: startSlot,
            end: endSlot,
            displayEnd,
            title: `${startSlot.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })} - ${displayEnd.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })}`,
            classNames: ['available-event', 'bg-fpcred', 'bg-opacity-70', 'cursor-pointer'],
            textColor: undefined,
            editable: true,
          });
          currentTime += stepMs;
        }
      }
      date.setDate(date.getDate() + 1);
      date.setHours(0, 0, 0, 0);
    }

    // Unifichiamo gli eventi di background
    const mergedBackgroundSlots = mergeIntervals(backgroundSlots);

    // Creiamo gli eventi di background unificati
    const unifiedBackgroundEvents = mergedBackgroundSlots.map((interval, idx) => ({
      id: `unified-overlap-${idx}`,
      start: interval.start,
      end: interval.end,
      display: 'background',
      classNames: ['stripe-background', 'cursor-not-allowed', 'select-none'],
      editable: false,
      extendedProps: {
        eventType: 'unavailable',
      },
    }));

    // Aggiungiamo gli eventi di background unificati agli altri slot
    setTimeSlots([...slots, ...unifiedBackgroundEvents]);
  };

  // Genera gli slot solo una volta quando lessons è popolato
  useEffect(() => {
    generateTimeSlots();
    setSlotsGenerated(true);
  }, [lessons]);

  const handleEventClick = (info: any) => {
    // Se l'evento contiene la classe 'bookedLesson', non fare nulla
    if (info.event.classNames.includes('bookedLesson')) {
      return;
    }

    const start = info.event.start as Date;
    const end = info.event.extendedProps.displayEnd as Date;

    if (start && end) {
      handleSlotClick(start, end);
    } else {
      console.error('Slot selezionato non valido');
    }
  };

  return (
    <>
      {screenWidth < 1024 && <DaysSlider selectedDate={selectedDate} onDateChange={handleDateChange} />}
      <FullCalendar
        firstDay={1}
        key={screenWidth < 1024 ? 'mobile' : 'desktop'}
        locale={'it'}
        ref={calendarRef}
        plugins={[timeGridPlugin, interactionPlugin]}
        initialView={screenWidth < 1024 ? 'timeGridDay' : 'timeGridWeek'}
        initialDate={selectedDate}
        headerToolbar={
          screenWidth >= 1024
            ? {
                start: 'title',
                center: '',
                end: 'prev,today,next',
              }
            : { start: 'title', center: '', end: 'prev,next' }
        }
        buttonText={{ today: 'Oggi' }}
        titleFormat={{ month: 'long' }}
        dayHeaderContent={
          screenWidth >= 1024
            ? (args) => {
                const date = args.date;
                const dayNumber = date.getDate();
                const weekdayName = date.toLocaleDateString(undefined, { weekday: 'short' });
                return (
                  <div className="custom-day-header p-2 rounded-full">
                    <div className="text-xl">{dayNumber}</div>
                    <div>{weekdayName}</div>
                  </div>
                );
              }
            : false
        }
        editable={false}
        selectable={false}
        events={timeSlots}
        eventClick={handleEventClick}
        selectMirror={true}
        weekends={true}
        validRange={{ start: start, end: maxDate }}
        allDaySlot={false}
        height={'100%'}
        slotDuration={'00:30:00'}
        slotMinTime={'07:00:00'}
        slotMaxTime={'24:00:00'}
        eventContent={(arg) => <div className="event-title">{arg.event.title}</div>}
        datesSet={(dateInfo) => {
          const newDate = new Date(dateInfo.start);
          // Aggiorna selectedDate solo se è diverso (confrontando la data)
          if (newDate.toDateString() !== selectedDate.toDateString()) {
            setSelectedDate(newDate);
          }
        }}
      />
    </>
  );
};

export default EditBookedLessonTeacherCalendar;
