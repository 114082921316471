import React, { useState, useEffect } from 'react';
import { ProfileComponent } from '../../pages/teacher/Profile';
import { TeacherInfo } from '../../model/TeacherInfo';
import api from '../../utils/api';
import { useLoading } from '../../context/LoadingContext';
import { useError } from '../../context/ErrorContext';
import { loadSvg } from '../../utils/loadSvg';
import { SubjectInfo } from '../../model/TeacherInfo';
import { BookPlus } from 'lucide-react';

interface Props {
  goTo: (component: ProfileComponent) => void;
  onSelectSubject: (subjectInfo: SubjectInfo, imageUrl: string) => void;
  onAddSubject: (teacherInfo) => void;
}

const ChangeSubject: React.FC<Props> = ({ goTo, onSelectSubject, onAddSubject }) => {
  const [teacherInfo, setTeacherInfo] = useState<TeacherInfo | null>(null);
  const { setLoading } = useLoading();
  const { setError } = useError();

  const [images, setImages] = React.useState<{ [key: string]: string }>({});

  // Funzione per caricare le immagini delle materie
  const loadSubjectImages = async (subjectsData: any[]) => {
    const newImages: { [key: string]: string } = {};
    for (const subject of subjectsData) {
      try {
        newImages[subject.subject.name] = await loadSvg(subject.subject.name);
      } catch (error) {
        console.error(`Errore nel caricamento dell'immagine per ${subject.subject.name}:`, error);
      }
    }
    setImages(newImages);
  };

  useEffect(() => {
    const fetchTeacherData = async () => {
      setLoading(true);
      try {
        const response = await api.get('/teacher');
        const teacher: TeacherInfo = response.data.user;
        setTeacherInfo(teacher);
        await loadSubjectImages(teacher.subjects);
      } catch (error) {
        console.error(error);
        setError(true, error.response?.data?.message || 'Errore sconosciuto', error.response?.status);
      } finally {
        setLoading(false);
      }
    };

    fetchTeacherData();
  }, [setError]);

  if (!teacherInfo) {
    return <div>Caricamento...</div>;
  }

  return (
    <>
      <div className="w-full p-4 grid grid-cols-2 md:grid-cols-4 gap-4 h-full overflow-y-auto justify-center">
        {teacherInfo.subjects && teacherInfo.subjects.length > 0 ? (
          <>
            {teacherInfo.subjects.map((subjectInfo, index) => (
              <div
                key={index}
                onClick={() => onSelectSubject(subjectInfo, images[subjectInfo.subject.name])}
                className="p-4 border rounded-lg bg-gray-50 shadow-sm h-32 min-w-32 flex flex-col items-center justify-center cursor-pointer">
                <img src={images[subjectInfo.subject.name]} alt={subjectInfo.subject.name} className="w-16 h-16" />
                <h3 className="font-semibold text-center">{subjectInfo.subject.name}</h3>
              </div>
            ))}
            {/* Aggiungi il pulsante extra qui */}
          </>
        ) : (
          <p>Nessuna materia trovata per questo insegnante.</p>
        )}
        <div
          key="AddSubject"
          onClick={() => onAddSubject(teacherInfo)}
          className="p-4 border rounded-lg bg-gray-50 shadow-sm h-32 min-w-32 flex flex-col items-center justify-center cursor-pointer">
          <BookPlus className="w-16 h-16"></BookPlus>
          <h3 className="font-semibold text-center">Aggiungi materia</h3>
        </div>
      </div>
    </>
  );
};

export default ChangeSubject;
