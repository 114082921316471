import React, { useEffect } from 'react';
import api from '../utils/api';
import { ReactComponent as Avatar } from '../ui/Avatar.svg';
import Calendario from './Calendar';
import { BookLessonData } from '../model/BookLessonData';
import { DateSelectArg } from '@fullcalendar/core';
import { useNavigate } from 'react-router-dom';
import { useLoading } from '../context/LoadingContext';
import { useError } from '../context/ErrorContext';
import { loadSvg } from '../utils/loadSvg';
import { ArrowBigLeft, BadgeEuro, Calendar, Clock, MapPin, User } from 'lucide-react';
import { motion } from 'framer-motion';
import { TeacherInfo } from '../model/TeacherInfo';

interface BookLessonProps {
  closeModal: () => void;
}

const BookLesson: React.FC<BookLessonProps> = ({ closeModal }) => {
  type steps =
    | 'selectChild'
    | 'selectSubject'
    | 'selectDuration'
    | 'selectLocation'
    | 'selectTeacher'
    | 'selectDate'
    | 'selectAvailableTeacher'
    | 'confirmFavoriteTeacher'
    | 'payment';

  const [step, setStep] = React.useState<steps>('selectChild');
  const [familyData, setFamilyData] = React.useState<any>();
  const [bookLessonData, setBookLessonData] = React.useState<BookLessonData>({} as BookLessonData);
  const [subjects, setSubjects] = React.useState<any[]>([]);
  const [durations, setDurations] = React.useState<any>([]);
  const [locations, setLocations] = React.useState<any[]>([]);
  const [teachers, setTeachers] = React.useState<any[]>(undefined);
  const [prices, setPrices] = React.useState<any[]>([]);
  const [teachersInfo, setTeachersInfo] = React.useState<TeacherInfo[]>([]);
  const [availableTeachers, setAvailableTeachers] = React.useState<any>({
    exactAvailabilities: [],
    nearAvailabilities: [],
  });
  const { setLoading } = useLoading();
  const { setError } = useError();
  const router = useNavigate();
  const [images, setImages] = React.useState<{ [key: string]: string }>({});

  // Funzione che calcola il messaggio di modifica/cancellazione in base alla data di inizio (startDate).
  function getModificationMessage(startDate: Date): string {
    // Sottrai 48 ore
    const cutoffDate = new Date(startDate.getTime() - 48 * 60 * 60 * 1000);
    const now = new Date();

    if (cutoffDate <= now) {
      // Se il cutoff è già passato rispetto a 'adesso'
      return 'Questa lezione non potrà essere modificata o cancellata perchè mancano meno di 48 ore al suo inizio';
    } else {
      // Altrimenti formatta data e ora per il cutoff
      const cutoffDateString = cutoffDate.toLocaleDateString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      const cutoffTimeString = cutoffDate.toLocaleTimeString('it-IT', {
        hour: '2-digit',
        minute: '2-digit',
      });
      return `Cancellazione o modifica gratuita prima del ${cutoffDateString} alle ore ${cutoffTimeString}`;
    }
  }

  const loadSubjectImages = async (subjectsData: any[]) => {
    const newImages: { [key: string]: string } = {};
    for (const subject of subjectsData) {
      newImages[subject.name] = await loadSvg(subject.name);
    }
    setImages(newImages);
  };

  useEffect(() => {
    console.log('sono nello useEffect: ', step);
    switch (step) {
      case 'selectChild':
        getFamilyInfo();
        break;
      case 'selectSubject':
        getSubjects();
        break;
      case 'selectDuration':
        getAvailableDuration();
        console.log('selectDuration');
        break;
      case 'selectLocation':
        getAvailableLocations();
        break;
      case 'selectTeacher':
        getFavoritesTeachers();
        break;
      case 'selectDate':
        console.log('selectDate');
        console.log('bookLessonData: ', bookLessonData);
        break;
      case 'selectAvailableTeacher':
        getPrice();
        getAvailableTeachers();
        break;
      case 'confirmFavoriteTeacher':
        getPrice();
        getAvailableTeachers();
        break;
      case 'payment':
        break;
    }
  }, [step]);

  useEffect(() => {
    console.log('familyDataSons: ', familyData);
    if (familyData && familyData.sons && familyData.sons.length === 1) {
      selectChild(0);
    }
  }, [familyData]);

  function getFamilyInfo() {
    if (!sessionStorage.getItem('familyData')) {
      setLoading(true);
      api
        .get('/family')
        .then((response) => {
          const familyData = response.data;
          console.log(familyData);
          setFamilyData({ ...familyData, status: undefined });
          sessionStorage.setItem('familyData', JSON.stringify({ ...familyData, status: undefined }));
        })
        .catch((error) => {
          console.error(error);
          setError(true, error.response.data.message, error.response.status);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const familyData = JSON.parse(sessionStorage.getItem('familyData')!);
      console.log('familyData: ', familyData);
      setFamilyData(familyData);
    }
  }

  async function getSubjects() {
    let subjectsData;
    if (!sessionStorage.getItem('subjects')) {
      setLoading(true);
      api
        .get(`family/student/${bookLessonData.child.id}/subject`)
        .then(async (response) => {
          subjectsData = response.data.subjects;
          setSubjects(subjectsData);
          sessionStorage.setItem('subjects', JSON.stringify(subjectsData));
          await loadSubjectImages(subjectsData);
        })
        .catch((error) => {
          console.error(error);
          setError(true, error.response.data.message, error.response.status);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      subjectsData = JSON.parse(sessionStorage.getItem('subjects')!);
      setSubjects(subjectsData);
      await loadSubjectImages(subjectsData);
    }
  }

  async function getAvailableDuration() {
    setLoading(true);
    api
      .get(`lesson/available-lengths?subject_id=${bookLessonData.subject.id}&student_id=${bookLessonData.child.id}`)
      .then((response) => {
        setDurations(response.data.lengths);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function getAvailableLocations() {
    setLoading(true);
    api
      .get(
        `lesson/available-locations?subject_id=${bookLessonData.subject.id}&student_id=${bookLessonData.child.id}&lesson_length=${bookLessonData.duration}`
      )
      .then((response) => {
        setLocations(response.data.locations);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function getFavoritesTeachers() {
    setLoading(true);
    api
      .get('family/teacher')
      .then((response) => {
        const teachers = response.data.teachers;
        console.log('teachers: ', teachers);
        if (teachers.length === 0) {
          setStep('selectDate');
          setTeachers(undefined);
          return;
        }
        setTeachers(teachers);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function getAvailableTeachers() {
    setLoading(true);
    try {
      const availabilityRequest = {
        student_id: bookLessonData.child.id,
        teacher_id: bookLessonData.teacher ? bookLessonData.teacher.id : undefined,
        subject_id: bookLessonData.subject.id,
        location: bookLessonData.location,
        required_lessons: [
          { start_date_time: bookLessonData.date.startStr, end_date_time: bookLessonData.date.endStr },
        ],
      };
      const response = await api.post('availability/search', availabilityRequest);
      const availabilities = response.data.availabilities[0];
      console.log('availabilities: ', availabilities);

      // Ottieni le lezioni già prenotate per verificare sovrapposizioni
      const bookedLessonsResponse = await api.get(`/lesson`);
      const filteredLessons = (bookedLessonsResponse.data?.lessons || []).filter((lesson) => {
        return lesson.status !== 'deleted' && lesson.status !== 'placeholder';
      });

      // Filtra le near_availabilities per rimuovere quelle che si sovrappongono a lezioni esistenti
      const filteredNearAvailabilities = availabilities.near_availabilities.filter((availability: any) => {
        const availabilityStart = new Date(availability.start_date_time);
        const availabilityEnd = new Date(availability.end_date_time);

        // Controlla se questa disponibilità si sovrappone a qualsiasi lezione prenotata
        return !filteredLessons.some((lesson: any) => {
          const lessonStart = new Date(lesson.start_time);
          const lessonEnd = new Date(lesson.end_time);

          // Verifica sovrapposizione: se l'inizio della disponibilità è prima della fine della lezione
          // E la fine della disponibilità è dopo l'inizio della lezione
          return (
            (availabilityStart < lessonEnd && availabilityEnd > lessonStart) ||
            (lessonStart < availabilityEnd && lessonEnd > availabilityStart)
          );
        });
      });

      // Aggiorna le near_availabilities con quelle filtrate
      availabilities.near_availabilities = filteredNearAvailabilities;

      // Combina le disponibilità esatte e vicine
      const allAvailabilities = [...availabilities.exact_availabilities, ...availabilities.near_availabilities];

      // Trova gli ID degli insegnanti unici
      const uniqueTeacherIdsSet = new Set(allAvailabilities.map((availability: any) => availability.teacher_id));
      const uniqueTeacherIds = Array.from(uniqueTeacherIdsSet);

      // Ottieni le informazioni di tutti gli insegnanti necessari direttamente dall'API
      const teacherInfosPromises = uniqueTeacherIds.map(async (teacherId) => {
        const response = await api.get(`family/teacher/${teacherId}`);
        return response.data.teacher;
      });

      const fetchedTeacherInfos = await Promise.all(teacherInfosPromises);

      // Aggiorna lo stato con le informazioni degli insegnanti
      setTeachersInfo(fetchedTeacherInfos);

      // Imposta gli insegnanti disponibili
      setAvailableTeachers({
        exactAvailabilities: availabilities.exact_availabilities,
        nearAvailabilities: availabilities.near_availabilities,
      });
    } catch (error: any) {
      console.error(error);
      setError(true, error.response?.data?.message, error.response?.status);
    } finally {
      setLoading(false);
    }
  }

  async function getPrice() {
    api.get('/lesson/price').then((response) => {
      console.log('i prezzi:', response.data.prices);
      setPrices(response.data.prices);
    });
  }

  function getPriceForDuration(duration: number): string {
    const priceObj = prices.find((p: any) => p.length === duration);
    return priceObj ? `${priceObj.price} €` : '';
  }

  function selectChild(index: number) {
    setBookLessonData({ ...bookLessonData, child: familyData?.sons[index] });
    setStep('selectSubject');
  }

  function selectSubject(subject: any) {
    setBookLessonData({ ...bookLessonData, subject });
    console.log('bookLessonData: ', bookLessonData);
    setStep('selectDuration');
  }
  function selectDuration(duration: number) {
    setBookLessonData({ ...bookLessonData, duration });
    console.log('bookLessonData: ', bookLessonData);
    setStep('selectLocation');
  }
  function selectLocation(location: string) {
    setBookLessonData({ ...bookLessonData, location });
    console.log('bookLessonData: ', bookLessonData);
    setStep('selectTeacher');
  }
  function selectTeacher(teacher?: any) {
    setBookLessonData({ ...bookLessonData, teacher });
    console.log("bookLessonData dopo la scelta dell'insegnante: ", bookLessonData);
    setStep('selectDate');
  }

  function goBack() {
    switch (step) {
      case 'selectSubject':
        setStep('selectChild');
        break;
      case 'selectDuration':
        setStep('selectSubject');
        break;
      case 'selectLocation':
        setStep('selectDuration');
        break;
      case 'selectTeacher':
        setStep('selectLocation');
        break;
      case 'selectDate':
        teachers ? setStep('selectTeacher') : setStep('selectLocation');
        break;
      case 'confirmFavoriteTeacher':
        setStep('selectDate');
        break;
      case 'selectAvailableTeacher':
        setStep('selectDate');
        break;
    }
  }

  function handleDateSelect(selectInfo: DateSelectArg) {
    const selectedDateStart = new Date(selectInfo.startStr);
    console.log('selectedDateStart: ', selectedDateStart);
    const selectedDateEnd = new Date(selectInfo.startStr);
    console.log('selectedDateEnd: ', selectedDateEnd);
    selectedDateEnd.setMinutes(selectedDateEnd.getMinutes() + bookLessonData.duration * 60);

    // Ensure the selected dates are in local time
    const selectedDateStartLocal = new Date(
      selectedDateStart.getTime() - selectedDateStart.getTimezoneOffset() * 60000
    );
    const selectedDateEndLocal = new Date(selectedDateEnd.getTime() - selectedDateEnd.getTimezoneOffset() * 60000);

    selectInfo.end = selectedDateEnd;
    selectInfo.endStr = selectedDateEndLocal.toISOString().slice(0, 19).replace('T', ' ');
    selectInfo.startStr = selectedDateStartLocal.toISOString().slice(0, 19).replace('T', ' ');

    console.log('selectInfo: ', selectInfo);
    setBookLessonData({ ...bookLessonData, date: selectInfo });
    if (bookLessonData.teacher) {
      setStep('confirmFavoriteTeacher');
    } else {
      setStep('selectAvailableTeacher');
    }
  }

  function goToStripe(availability?: any) {
    closeModal();
    setLoading(true);

    const payload = availability
      ? {
          start_date_time: availability.start_date_time,
          location: bookLessonData.location,
          subject_id: bookLessonData.subject.id,
          teacher_id: availability.teacher_id,
          student_id: bookLessonData.child.id,
          size: bookLessonData.duration,
        }
      : {
          start_date_time: bookLessonData.date.startStr,
          location: bookLessonData.location,
          subject_id: bookLessonData.subject.id,
          teacher_id: bookLessonData.teacher.id,
          student_id: bookLessonData.child.id,
          size: bookLessonData.duration,
        };
    api
      .post('lesson/single', payload)
      .then((response) => {
        console.log('response: ', response.data);
        window.location.href = response.data.payment_url;
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function TeacherCard({
    teacher,
    availability,
    goToStripe,
    isNearAvailability = false,
  }: {
    teacher: any;
    availability: any;
    goToStripe: (availability?: any) => void;
    isNearAvailability?: boolean;
  }) {
    console.log('teacher: ', teacher);
    return (
      <div className="flex flex-col border-2 rounded-lg p-4 bg-white mb-4 hover:shadow-lg hover:border-fpcred transition-all duration-300 transform hover:-translate-y-1">
        <div className="flex gap-4">
          {/* Avatar container con dimensioni fisse */}
          <div className="flex-shrink-0 w-24 h-24 md:w-32 md:h-32 flex items-center justify-center bg-gray-50 rounded-lg">
            <User className="w-16 h-16 md:w-20 md:h-20 text-gray-500" />
          </div>

          {/* Contenuto della card */}
          <div className="flex flex-col flex-grow">
            {/* Nome insegnante */}
            <div className="flex flex-row gap-2 mb-2">
              <p className="text-xl font-semibold text-fpcred">{teacher.first_name}</p>
              <p className="text-xl font-semibold text-fpcred">{teacher.last_name}</p>
            </div>

            {/* Badge orario per disponibilità vicine */}
            {isNearAvailability && (
              <div className="inline-flex items-center bg-[#f3e8e8] text-[#d10c33] font-bold px-4 py-1 rounded-full text-sm mb-2 self-start">
                <Clock className="w-4 h-4 mr-1" />
                {new Date(availability.start_date_time).toLocaleTimeString('it-IT', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
                {' / '}
                {new Date(availability.end_date_time).toLocaleTimeString('it-IT', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </div>
            )}

            {/* Bio con altezza fissa e overflow */}
            <div className="mb-4 h-20 overflow-y-auto">
              <p className="text-base text-gray-700">{teacher.bio ? teacher.bio : 'Nessuna bio disponibile'}</p>
            </div>

            {/* Pulsante prenota */}
            <div className="mt-auto">
              <button
                className="bg-[#d10c33] px-4 py-2 rounded-xl text-white hover:bg-[#d10c33]/90 transition-all duration-300 shadow-sm hover:shadow-md flex items-center"
                onClick={() => goToStripe(availability)}>
                <Calendar className="w-4 h-4 mr-2" />
                Prenota
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2 h-full">
      <div className="absolute top-6 left-4">
        {step !== 'selectChild' && !(step === 'selectSubject' && familyData?.sons?.length === 1) && (
          <div className="w-10 h-10">
            <ArrowBigLeft className="cursor-pointer w-full h-full text-fpcred" onClick={goBack} />
          </div>
        )}
      </div>

      {/* STEP SELECT CHILD */}
      {step === 'selectChild' && (
        <div className="flex flex-col">
          <h3 className="text-xl text-center">Per chi vuoi prenotare la lezione ?</h3>
          <div className="flex flex-col items-center justify-center gap-3 p-5">
            {familyData?.sons?.map((son: any, index: number) => (
              <div
                key={index}
                className="flex p-4 rounded-lg border-y-2 border-y-fpcred gap-16 items-center w-full md:w-96 shadow-md cursor-pointer transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg"
                onClick={() => selectChild(index)}>
                <Avatar />
                <div className="flex flex-col items-center justify-center">
                  <div className="flex flex-col">
                    <p className="text-base">Nome</p>
                    <span className="text-xl">{son.first_name}</span>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-base">Cognome</p>
                    <span className="text-xl">{son.last_name}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* STEP SELECT SUBJECT */}
      {step === 'selectSubject' && subjects && (
        <div className="flex flex-col items-center justify-center">
          <h3 className="text-xl text-center mb-4">Di quale materia ha bisogno?</h3>
          <div className="grid grid-cols-2 gap-6 p-5 justify-center">
            {subjects.map((subject: any, index: number) => (
              <div
                key={index}
                className="flex flex-col items-center justify-center p-4 rounded-lg bg-fpcwhite w-36 h-36 md:w-48 md:h-48 shadow-md cursor-pointer transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg hover:border-fpcred border-2"
                onClick={() => selectSubject(subject)}>
                <img src={images[subject.name]} alt={subject.name} className="w-24 h-24 md:w-32 md:h-32 mb-2" />
                <div className="text-center">
                  <span className="text-xl">{subject.name}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* STEP SELECT DURATION */}
      {step === 'selectDuration' && durations && (
        <div className="flex flex-col items-center justify-center">
          <h3 className="text-2xl text-center">Quanto tempo vuoi prenotare?</h3>
          <div className="flex flex-wrap justify-around gap-6 p-5">
            {durations.map((duration: any, index: number) => (
              <div
                key={index}
                className={`flex items-center justify-center p-2 rounded-xl bg-fpcwhite w-36 h-36 shadow-md transition duration-300 ease-in-out border-2 ${
                  duration.enabled
                    ? 'hover:border-fpcred hover:scale-105 hover:shadow-lg cursor-pointer'
                    : 'opacity-50 cursor-not-allowed'
                }`}
                onClick={() => {
                  if (duration.enabled) {
                    selectDuration(duration.length);
                  }
                }}>
                <div className="flex flex-col items-center justify-center h-full w-full">
                  <div className="text-center">
                    <span className="text-2xl">
                      {duration.length === 1 && '1 ora'}
                      {duration.length === 1.5 && '1 ora e mezza'}
                      {duration.length === 2 && '2 ore'}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* STEP SELECT LOCATION */}
      {step === 'selectLocation' && locations && (
        <div className="flex flex-col">
          <h3 className="text-xl text-center">Dove vuoi fare la lezione?</h3>
          <div className="flex flex-wrap justify-around gap-6 p-5">
            {Object.entries(locations).map((location, index) => (
              <div
                key={index}
                className={`flex items-center justify-center p-4 rounded-lg border-2 bg-fpcwhite w-36  ${
                  (location as any)[1].available
                    ? 'cursor-pointer hover:border-fpcred shadow-md transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg'
                    : 'opacity-25 cursor-not-allowed'
                }`}
                onClick={() => (location as any)[1].available && selectLocation(location[0])}>
                <div className="flex flex-col items-center justify-center h-full w-full">
                  <div className="text-center">
                    <span className="text-xl">{location[0]}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* STEP SELECT TEACHER */}
      {step === 'selectTeacher' && teachers && (
        <div className="flex flex-col">
          <h3 className="text-xl text-center">Quale insegnante vuoi prenotare?</h3>
          <div className="flex justify-center gap-6 p-5">
            {teachers.map((teacher: any, index: number) => (
              <div
                key={index}
                onClick={() => selectTeacher(teacher)}
                className="flex items-center justify-center p-4 rounded-lg border border-black w-36 shadow-md cursor-pointer transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg">
                <div className="flex flex-col items-center justify-center h-full w-full">
                  <div className="text-center">
                    <span className="text-xl">{teacher.first_name}</span>
                  </div>
                </div>
              </div>
            ))}
            <div
              className="flex items-center justify-center p-4 rounded-lg border border-black w-36 shadow-md cursor-pointer transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg"
              onClick={() => selectTeacher()}>
              <div className="flex flex-col items-center justify-center h-full w-full">
                <div className="text-center">
                  <span className="text-xl">Nessuna preferenza</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* STEP SELECT DATE */}
      {step === 'selectDate' && <Calendario bookLessonData={bookLessonData} onDateSelect={handleDateSelect} />}

      {step === 'confirmFavoriteTeacher' && (
        <div className="px-4 sm:px-8">
          <h1 className="text-2xl sm:text-3xl font-bold mb-8 text-center text-[#d10c33]">Conferma Prenotazione</h1>
          {/* Riepilogo Lezione (a sinistra) */}
          <div>
            <motion.div initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }}>
              <div className="bg-fpcwhite rounded-lg p-4 md:p-6 shadow-md flex flex-col md:flex-row gap-4">
                <div className="w-full p-4 flex flex-col">
                  <h2 className="text-xl sm:text-2xl font-bold text-[#d10c33] mb-4">Riepilogo Lezione</h2>
                  <div className="flex flex-col gap-3">
                    <div className="flex items-center">
                      <Calendar className="mr-2 h-5 w-5 text-[#d10c33]" />
                      <span>{bookLessonData.date.start.toLocaleDateString('it-IT')}</span>
                      <span className="ml-2">
                        {bookLessonData.date.start
                          .toLocaleDateString('it-IT', { weekday: 'long' })
                          .charAt(0)
                          .toUpperCase() +
                          bookLessonData.date.start.toLocaleDateString('it-IT', { weekday: 'long' }).slice(1)}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <Clock className="mr-2 h-5 w-5 text-[#d10c33]" />
                      <span>
                        {bookLessonData.date.start.toLocaleTimeString('it-IT', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                        /
                        {bookLessonData.date.end.toLocaleTimeString('it-IT', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <MapPin className="mr-2 h-5 w-5 text-[#d10c33]" />
                      <span>{bookLessonData.location}</span>
                    </div>
                    <div className="flex items-center">
                      <BadgeEuro className="mr-2 h-5 w-5 text-[#d10c33]" />
                      <span>{getPriceForDuration(bookLessonData.duration)}</span>
                    </div>
                    {/* *** Qui usiamo la nuova logica *** */}
                    <div className="flex items-center">
                      <p className="italic text-black">{getModificationMessage(bookLessonData.date.start)}</p>
                    </div>
                  </div>
                </div>
                <div className="p-4 w-full flex flex-col gap-4">
                  <h2 className="text-xl sm:text-2xl font-bold text-[#d10c33]">Insegnante Preferito</h2>
                  <div className="flex items-center gap-4 w-full p-2">
                    <Avatar />
                    <div className="flex flex-col md:flex-row md:gap-2">
                      <p className="text-lg sm:text-xl font-semibold">{bookLessonData.teacher.first_name}</p>
                      <p className="text-lg sm:text-xl font-semibold">{bookLessonData.teacher.last_name}</p>
                    </div>
                  </div>
                  <div className="flex items-center justify-center">
                    <button
                      className="bg-[#d10c33] px-4 py-2 rounded-xl text-white hover:bg-[#d10c33]/90 transition-colors duration-300"
                      onClick={() => goToStripe()}>
                      Vai al pagamento
                    </button>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
          {/* Disponibilità alternative dell'insegnante preferito */}
          {availableTeachers.nearAvailabilities.length > 0 && (
            <div className="mt-6">
              <h2 className="text-lg sm:text-xl font-bold text-[#d10c33] mb-4">
                Disponibilità alternative dell'insegnante
              </h2>
              <div className="relative">
                <div className="flex overflow-x-auto py-3 px-2 gap-4 snap-x snap-mandatory scroll-smooth select-none cursor-grab active:cursor-grabbing">
                  {availableTeachers.nearAvailabilities
                    .filter((availability) => availability.teacher_id === bookLessonData.teacher.id)
                    .map((availability, index) => (
                      <div key={index} className="snap-start flex-shrink-0">
                        <TeacherCard
                          teacher={bookLessonData.teacher}
                          availability={availability}
                          isNearAvailability
                          goToStripe={goToStripe}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {/* STEP SELECT AVAILABLE TEACHER */}
      {step === 'selectAvailableTeacher' && availableTeachers && (
        <div className="container mx-auto max-w-7xl overflow-auto w-full">
          <h1 className="text-3xl font-bold mb-8 text-center text-[#d10c33]">Conferma Prenotazione</h1>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Riepilogo Lezione (a sinistra) */}
            <div className="lg:col-span-1 w-full">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}>
                <div className="bg-fpcwhite rounded-lg p-6 shadow-md w-full">
                  <h2 className="text-2xl font-bold text-[#d10c33] mb-4">Riepilogo Lezione</h2>
                  <div className="space-y-4">
                    <div className="flex items-center">
                      <Calendar className="mr-2 h-5 w-5 text-[#d10c33]" />
                      <span>{bookLessonData.date.start.toLocaleDateString('it-IT')}</span>
                      <span className="ml-2">
                        {bookLessonData.date.start
                          .toLocaleDateString('it-IT', { weekday: 'long' })
                          .charAt(0)
                          .toUpperCase() +
                          bookLessonData.date.start.toLocaleDateString('it-IT', { weekday: 'long' }).slice(1)}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <Clock className="mr-2 h-5 w-5 text-[#d10c33]" />
                      <span>
                        {bookLessonData.date.start.toLocaleTimeString('it-IT', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                        /
                        {bookLessonData.date.end.toLocaleTimeString('it-IT', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <MapPin className="mr-2 h-5 w-5 text-[#d10c33]" />
                      <span>{bookLessonData.location}</span>
                    </div>
                    <div className="flex items-center">
                      <BadgeEuro className="mr-2 h-5 w-5 text-[#d10c33]" />
                      <span>{getPriceForDuration(bookLessonData.duration)}</span>
                    </div>

                    <div className="flex items-center">
                      <p className="italic text-black">{getModificationMessage(bookLessonData.date.start)}</p>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>

            <div className="bg-fpcwhite w-full h-full rounded-lg p-2 md:p-6 shadow-md md:col-span-2">
              <h2 className="text-2xl font-bold text-[#d10c33] mb-4">Insegnanti Disponibili</h2>
              <div className="flex flex-col h-full">
                <div className="mb-6">
                  <h3 className="text-xl font-semibold mb-2">Disponibilità per l'orario richiesto</h3>
                  <div className="relative">
                    <div className="flex overflow-x-auto py-4 px-2 gap-4 snap-x snap-mandatory scroll-smooth select-none cursor-grab active:cursor-grabbing">
                      {availableTeachers.exactAvailabilities.length > 0 ? (
                        availableTeachers.exactAvailabilities.map((availability: any, index: number) => {
                          const teacher = teachersInfo.find((t: any) => t.id === availability.teacher_id);
                          return teacher ? (
                            <div key={index} className="snap-start flex-shrink-0">
                              <TeacherCard teacher={teacher} availability={availability} goToStripe={goToStripe} />
                            </div>
                          ) : null;
                        })
                      ) : (
                        <p className="text-gray-500 italic py-2">Nessun insegnante disponibile per questo orario</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <h3 className="text-xl font-semibold mb-2">Disponibilità vicino all'orario richiesto</h3>
                  <div className="relative">
                    <div className="flex overflow-x-auto py-4 px-2 gap-4 snap-x snap-mandatory scroll-smooth select-none cursor-grab active:cursor-grabbing">
                      {availableTeachers.nearAvailabilities.length > 0 ? (
                        availableTeachers.nearAvailabilities.map((availability: any, index: number) => {
                          const teacher = teachersInfo.find((t: any) => t.id === availability.teacher_id);
                          return teacher ? (
                            <div key={index} className="snap-start flex-shrink-0">
                              <TeacherCard
                                teacher={teacher}
                                availability={availability}
                                goToStripe={goToStripe}
                                isNearAvailability
                              />
                            </div>
                          ) : null;
                        })
                      ) : (
                        <p className="text-gray-500 italic py-2">Nessun insegnante disponibile in orari vicini</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookLesson;
