import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import { DateSelectArg, EventInput, EventClickArg } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import itLocale from '@fullcalendar/core/locales/it';
import useScreenWidth from '../../hooks/useScreenWidth';
import dayGridPlugin from '@fullcalendar/daygrid';

interface CalendarProps {
  lessonsEvent: EventInput[];
  isOpenEditModal: (event: EventInput) => void;
  onDateClick?: (arg: { date: Date }) => void;
}

const BookedLessonsCalendar: React.FC<CalendarProps> = ({ lessonsEvent, isOpenEditModal, onDateClick }) => {
  const [events, setEvents] = useState<EventInput[]>(lessonsEvent);
  const [render, setRender] = useState(false);
  const [initialView, setInitialView] = useState<string>('dayGridMonth');
  const screenWidth = useScreenWidth();
  const [selectedEvent, setSelectedEvent] = useState<EventInput | null>(null);

  const today = new Date(Date.now());
  const start = new Date(new Date().setDate(today.getDate() - 60));
  const maxDate = new Date(new Date().setDate(today.getDate() + 90));

  useEffect(() => {
    if (screenWidth < 1024) {
      setInitialView('timeGridDay');
    } else {
      setInitialView('dayGridMonth');
      // setInitialView('timeGridWeek');
    }
  }, [screenWidth]);

  const handleEventClick = (clickInfo: EventClickArg) => {
    const eventIndex = events.findIndex((event) => event.id === clickInfo.event.id);
    if (clickInfo.event.groupId === 'lesson') {
      console.log(clickInfo);
      setSelectedEvent(events[eventIndex]);
      isOpenEditModal(events[eventIndex]);
    }
  };

  useEffect(() => {
    setEvents([...lessonsEvent.map((event) => ({ ...event, groupId: 'lesson' }))]);
  }, [lessonsEvent]);

  // useEffect(() => {
  //   setRender(true);
  // }, []);

  const handleDateClick = (arg: any) => {
    // if (arg.view.type === 'dayGridMonth' || arg.view.type === 'dayGridYear') {
    //   let calendarApi = arg.view.calendar;
    //   calendarApi.changeView('timeGridDay', arg.date);
    // }
    // Chiama la funzione onDateClick se è definita
    if (onDateClick) {
      onDateClick(arg);
    }
  };

  const selectAllow = (selectInfo: DateSelectArg) => {
    if (
      lessonsEvent.some(
        (lesson) =>
          selectInfo.start >= new Date(lesson.start_date_time) &&
          selectInfo.end <= new Date(lesson.end_date_time) &&
          selectInfo.end.getTime() - selectInfo.start.getTime() <= 30 * 60 * 1000
      )
    ) {
      return true;
    }
    return false;
  };

  const renderEventContent = (eventInfo) => {
    return eventInfo.event.extendedProps.customHtml;
  };



  return (
    <>
      <FullCalendar
        key={initialView}
        selectAllow={selectAllow}
        eventClick={handleEventClick}
        locale={itLocale}
        plugins={[timeGridPlugin, interactionPlugin, dayGridPlugin]}
        initialView={initialView}
        headerToolbar={{
          start: 'prev',
          center: 'title',
          end: 'next',
        }}
        editable={false}
        eventDragMinDistance={99}
        selectable={true}
        selectMirror={true}
        weekends={true}
        validRange={{ start: start, end: maxDate }}
        events={events}
        // select={(selectInfo: DateSelectArg) => selectDate(selectInfo)}
        dateClick={handleDateClick}
        allDaySlot={false}
        height={'100%'}
        eventContent={renderEventContent}
        slotMinTime={'07:00:00'}
        slotMaxTime={'22:00:00'}
        selectLongPressDelay={70}
      />
    </>
  );
};

export default BookedLessonsCalendar;
